import TableContainer from '@mui/material/TableContainer'
import Table from '@mui/material/Table'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Button from '@mui/material/Button'
import Paper from '@mui/material/Paper'
import { Box, Checkbox, Link, Typography, Dialog, DialogTitle, DialogContent, TextField } from "@mui/material"
import { PagesViewType } from "../app/Pages"
import { FormEvent } from 'react'

const PagesView: PagesViewType = ({ pages, tenant, onChangeEnable, onClickEdit, onChangeIsPublic, isShowBasicAuthForm, basicAuthForm, onChangeBasicAuthForm, onChangeIsBasicAuth, onSubmitBasicAuthForm, onCancelBasicAuthForm, onShowBasicAuthForm }) => {
    const renderIsPublic = () => {
        if (tenant.isPublic) {
            return (
                <>
                    <Box>
                        公開設定: <Typography component="span" sx={{ color: "blue" }}>公開</Typography>
                    </Box>
                    <Box sx={{ pt: 1 }}>
                        <Button variant="contained" color="primary" onClick={() => onChangeIsPublic(false)}>サイトを非公開にする</Button>
                    </Box>
                </>
            )
        } else {
            return (
                <>
                    <Box>
                        公開設定: <Typography component="span" sx={{ color: "red" }}>非公開</Typography>
                    </Box>
                    <Box sx={{ pt: 1 }}>
                        <Button variant="contained" color="primary" onClick={() => onChangeIsPublic(true)}>サイトを公開する</Button>
                    </Box>
                </>
            )
        }
    }
    const renderIsBasicAuth = () => {
        if (!tenant.isBasicAuth) {
            return (
                <>
                    <Box sx={{mt: 3}}>
                        パスワード認証: <Typography component="span" sx={{ color: "blue" }}>パスワード認証なし</Typography>
                    </Box>
                    <Box>
                        パスワード認証を設定すると、サイトの閲覧にパスワードが必要になり、検索エンジンからのアクセスもブロックします。
                    </Box>
                    <Box sx={{ pt: 1 }}>
                        <Button variant="contained" color="primary" onClick={() => onChangeIsBasicAuth(true)}>パスワード認証を設定する</Button>
                    </Box>
                </>
            )
        } else {
            return (
                <>
                    <Box sx={{mt: 3}}>
                        パスワード認証: <Typography component="span" sx={{ color: "red" }}>パスワード認証あり</Typography>
                    </Box>
                    <Box>
                        ユーザー名: {tenant.basicAuthUser}
                    </Box>
                    <Box>
                        パスワード: ********
                    </Box>
                    <Box sx={{ pt: 1 }}>
                        <Button variant="contained" color="primary" onClick={() => onChangeIsBasicAuth(false)}>パスワード認証を解除する</Button>
                        <Button sx={{ml: 2}} variant="contained" color="primary" onClick={() => onShowBasicAuthForm()} >ユーザーとパスワードを変更する</Button>
                    </Box>
                </>
            )
        }
    }
    return (
        <div>
            <Box sx={{ m: 2, p: 2 }} component={Paper}>
                <Typography variant="h6">サイト情報</Typography>
                {tenant.siteUrls.map(siteUrl => (
                    <Box key={siteUrl} sx={{ pb: 1 }}>
                        <Link href={siteUrl} target="_blank">{siteUrl}</Link>
                    </Box>
                ))}
                {renderIsPublic()}
                {renderIsBasicAuth()}
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>有効</TableCell>
                            <TableCell>ページ名</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {pages.map(page => (
                            <TableRow key={page.kind}>
                                <TableCell>
                                    <Checkbox checked={page.enable} onChange={evt => onChangeEnable(page, evt.target.checked)}></Checkbox>
                                </TableCell>
                                <TableCell>
                                    {page.title}
                                </TableCell>
                                <TableCell>
                                    <Button type="button" onClick={() => onClickEdit(page)}>コンテンツ編集</Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Dialog
                open={isShowBasicAuthForm}
                onClose={() => onCancelBasicAuthForm()}
            >
                <DialogTitle>限定公開パスワード設定</DialogTitle>
                <DialogContent>
                    <Box component="form" onSubmit={(evt:FormEvent) => {
                        evt.preventDefault()
                        onSubmitBasicAuthForm()
                    }}>
                        <Box sx={{p: 3}}>
                            <TextField
                                variant='outlined'
                                label="ユーザー名"
                                value={basicAuthForm.user}
                                onChange={evt => { onChangeBasicAuthForm("user", evt.target.value) }}
                            />
                        </Box>
                        <Box sx={{p: 3}}>
                            <TextField
                                variant='outlined'
                                label="パスワード"
                                value={basicAuthForm.password}
                                onChange={evt => { onChangeBasicAuthForm("password", evt.target.value) }}
                            />
                        </Box>
                        <Box sx={{p: 3}}>
                            <Button type="submit" disabled={basicAuthForm.user === "" || basicAuthForm.password === ""}>OK</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default PagesView


import { Box, Button, MenuItem, TextField } from "@mui/material"
import { ChangeEvent, FC, FormEvent, Fragment, useState } from "react"
import { Table, TableStyle } from "sgcms-models"
import { TableFormType } from "../app/PageContents"
import TextEditor from "./TextEditor"

const TableForm: TableFormType = ({ value: initialValue, onSubmit }) => {
    const [value, setValue] = useState<Table>(initialValue)
    const [selectedCol, setSelectedCol] = useState<[number, number] | undefined>(undefined)
    const handleSubmit = (evt: FormEvent) => {
        evt.preventDefault()
        onSubmit(value)
    }
    const handleChangeHeaderText = (val: string) => {
        setValue({ ...value, headerText: val })
    }
    const handleChangeFooterText = (val: string) => {
        setValue({ ...value, footerText: val })
    }
    const handleClickCol = (row: number, col: number) => () => {
        setSelectedCol([row, col])
    }

    /*
    const handleChangeCol = (evt:ChangeEvent<HTMLInputElement>) => {
        setValue(value => ({
            ...value,
            rows: value.rows.map((row, i) => {
                if (selectedCol == null || selectedCol[0] !== i) {
                    return row
                }
                return row.map((col, j) => {
                    if (selectedCol[1] !== j) {
                        return col
                    }
                    return evt.target.value
                })
            })
        }))
    }
    */
    const handleChangeCol = (ixRow: number, ixCol: number) => (evt: ChangeEvent<HTMLInputElement>) => {
        setValue(value => ({
            ...value,
            rows: value.rows.map((row, i) => {
                if (ixRow !== i) {
                    return row
                }
                return row.map((col, j) => {
                    if (ixCol !== j) {
                        return col
                    }
                    return evt.target.value
                })
            })
        }))
    }
    const renderCol = (row: number, col: number, colValue: string) => {
        /*
        if (selectedCol != null && row === selectedCol[0] && col === selectedCol[1]) {
            return (
                <td>
                    <input type="text" value={value} onChange={handleChangeCol} />
                </td>
            )
        }
        return <td onClick={handleClickCol(row, col)}>{value}</td>
        */
        const backgroundColor = () => {
            switch (value.tableStyle) {
                case 'table1':
                    if (row === 0) {
                        return 'rgba(0,0,255,0.5)'
                    } else {
                        return undefined
                    }
                case 'table2':
                    if (col === 0) {
                        return 'rgba(0,0,255,0.5)'
                    } else {
                        return undefined
                    }
                case 'table3':
                    if (row === 0 || col === 0) {
                        return 'rgba(0,0,255,0.5)'
                    } else {
                        return undefined
                    }
            }
        }

        const color = () => {
            switch (value.tableStyle) {
                case 'table1':
                    if (row === 0) {
                        return 'white'
                    } else {
                        return undefined
                    }
                case 'table2':
                    if (col === 0) {
                        return 'white'
                    } else {
                        return undefined
                    }
                case 'table3':
                    if (row === 0 || col === 0) {
                        return 'white'
                    } else {
                        return undefined
                    }
            }
        }

        return (
            <Box component="td" sx={{ position: "relative", height: '30px', width: '300px' }}>
                <Box
                    sx={{
                        // position: 'absolute',
                        // top: 0,
                        /*
                        height: '100%',
                        */
                        width: '100%',
                        backgroundColor: backgroundColor(),
                        color: color(),
                    }}
                    // component="input"
                    component={TextField}
                    variant="outlined"
                    multiline
                    rows={3}
                    type="text"
                    value={colValue}
                    onChange={handleChangeCol(row, col)}
                />
            </Box>
        )
    }
    const handleClickAddRow = () => {
        setValue({
            ...value,
            rows: [...value.rows, new Array(value.rows[0].length).fill("")],
        })
    }

    const handleClickAddCol = () => {
        setValue({
            ...value,
            rows: value.rows.map(row => [...row, ""])
        })
    }
    const handleClickDelRow = (ixRow:number) => () => {
        setValue({
            ...value,
            rows: value.rows.filter((row, i) => i !== ixRow)
        })
    }

    const handleClickDelCol = (ixCol:number) => () => {
        setValue({
            ...value,
            rows: value.rows.map(row => row.filter((col, i) => i !== ixCol)),
        })
    }

    const handleChangeStyle = (evt: ChangeEvent<HTMLInputElement>) => {
        setValue({
            ...value,
            tableStyle: evt.target.value as TableStyle,
        })
    }
    const renderColControls = () => {
        const a = []
        a.push(<td></td>)
        const n = value.rows[0].length
        for (let i = 0; i < n; i++) {
            const disabled = value.rows[0].length <= 1
            a.push(<td key={`${i}`}><Button type="button" variant="text" color="primary" disabled={disabled} onClick={handleClickDelCol(i)}>削除</Button> </td>)
        }
        return a
    }
    const renderRowControl = (ixRow: number) => {
        const disabled = value.rows.length <= 1
        return (
            <td>
                <Button type="button" variant="text" color="primary" disabled={disabled} onClick={handleClickDelRow(ixRow)}>削除</Button>
            </td>
        )
    }
    return (
        <form onSubmit={handleSubmit} className="table-form" >
            <TextField
                select
                value={value.tableStyle}
                onChange={handleChangeStyle}
            >
                <MenuItem value="table1">スタイルA</MenuItem>
                <MenuItem value="table2">スタイルB</MenuItem>
                <MenuItem value="table3">スタイルC</MenuItem>
            </TextField>
            <TextEditor value={value.headerText} onChange={handleChangeHeaderText} label="ヘッダー文" />
            <table>
                <tbody>
                    <tr>
                        {renderColControls()}
                    </tr>
                    {value.rows.map((row, i) => (
                        <tr key={`row${i}`}>
                            {renderRowControl(i)}
                            {row.map((col, j) => (
                                <Fragment key={`row${i}_col${j}`}>
                                    {renderCol(i, j, col)}
                                </Fragment>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <Button color="primary" variant="text" type="button" onClick={handleClickAddRow}>行を追加</Button>
            <Button color="primary" variant="text" type="button" onClick={handleClickAddCol}>列を追加</Button>
            <TextEditor value={value.footerText} onChange={handleChangeFooterText} label="フッター文" />
            <Button color="primary" type="submit" variant="contained">保存</Button>
        </form>
    )
}

export default TableForm

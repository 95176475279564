import { FC, useRef, useState, useCallback, ChangeEventHandler, ComponentType, PropsWithChildren } from 'react'
import { Editor, EditorState, convertToRaw, RichUtils, convertFromHTML, ContentState, CompositeDecorator, ContentBlock } from 'draft-js'
import 'draft-js/dist/Draft.css';
import { Button, Box, TextField } from '@mui/material';
import { Undo } from '@mui/icons-material'

const escape = require('escape-html')


const TextEditor: FC<Props> = (({ value, onChange, label }) => {

    const ref = useRef<Editor | null>(null)
    const [editorState, setEditorState] = useState<EditorState>(() => {
        const blocksFromHTML = convertFromHTML(value)
        const contentState = ContentState.createFromBlockArray(blocksFromHTML.contentBlocks, blocksFromHTML.entityMap)
        return EditorState.createWithContent(contentState, decorator)
    })
    const [linkInputState, setLinkInputState] = useState({ isShow: false, url: "" })
    const refLinkInput = useRef<HTMLInputElement | null>(null)
    const convertToHTML = (editorState: EditorState): string => {
        const ret = []
        const raw = convertToRaw(editorState.getCurrentContent())
        const { blocks, entityMap } = raw

        let i = 0;
        while (true) {
            if (i >= blocks.length) {
                break
            }
            if (blocks[i].type === 'unordered-list-item') {
                ret.push('<ul>')
                while (i < blocks.length && blocks[i].type === 'unordered-list-item') {
                    ret.push(`<li>${blocks[i].text}</li>`)
                    i++
                }
                ret.push('</ul>')
                continue
            }
            if (blocks[i].type === 'unstyled') {
                let c = 0
                const a: string[] = []
                const inlineList: Inline[] = []
                for (let j = 0; j < blocks[i].entityRanges.length; j++) {
                    const range = blocks[i].entityRanges[j]
                    // a.push(blocks[i].text.substring(c, range.offset))
                    const entity = entityMap[range.key]
                    if (entity.type === "LINK") {
                        inlineList.push({
                            type: 'link',
                            offset: range.offset,
                            text: blocks[i].text.substring(range.offset, range.offset + range.length),
                            link: entity.data.url,
                        })
                        // a.push(`<a href="${escape(entity.data.url)}">${blocks[i].text.substring(range.offset, range.offset + range.length)}</a>`)
                    }
                    // c = range.offset + range.length
                }
                for (let j = 0; j < blocks[i].inlineStyleRanges.length; j++) {
                    const range = blocks[i].inlineStyleRanges[j]
                    let type: Inline['type']
                    switch (range.style) {
                        case 'BOLD':
                            type = 'b'
                            break
                        case 'ITALIC':
                            type = 'i'
                            break
                        case 'UNDERLINE':
                            type = 'u'
                            break
                        case 'STRIKETHROUGH':
                            type = 'u'
                            break
                        default:
                            continue
                    }
                    inlineList.push({
                        type,
                        offset: range.offset,
                        text: blocks[i].text.substring(range.offset, range.offset + range.length),
                        link: '',
                    })
                }
                const l = inlineList.sort((a, b) => a.offset - b.offset)
                for (let j = 0; j < l.length; j++) {
                    const o = l[j]
                    if (c < o.offset) {
                        a.push(blocks[i].text.substring(c, o.offset))
                    }
                    switch (o.type) {
                        case 'b':
                            a.push(`<b>${o.text}</b>`)
                            break
                        case 'i':
                            a.push(`<i>${o.text}</i>`)
                            break
                        case 'u':
                            a.push(`<u>${o.text}</u>`)
                            break
                        case 's':
                            a.push(`<s>${o.text}</s>`)
                            break
                        case 'link':
                            a.push(`<a href="${escape(o.link)}">${o.text}</a>`)
                            break
                    }
                    c = o.offset + o.text.length
                }
                a.push(blocks[i].text.substring(c))
                ret.push(a.join(""))
            }
            if (blocks[i].type === 'header-three') {
                ret.push(`<h3>${escape(blocks[i].text)}</h3>`)
            }
            i++
        }
        return ret.join('<br />').replaceAll("\n", '<br />')
    }
    const handleChangeEditorState = (editorState: EditorState) => {
        setEditorState(editorState)
        onChange(convertToHTML(editorState))
    }
    const handleChangeLinkUrl: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = useCallback(evt => {
        evt.preventDefault()
        setLinkInputState(state => ({
            ...state,
            url: evt.target.value,
        }))
    }, [])
    const confirmLink = () => {
        const contentState = editorState.getCurrentContent();
        const contentStateWithEntity = contentState.createEntity(
            'LINK',
            'MUTABLE',
            { url: linkInputState.url }
        );
        const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
        const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity });
        setEditorState(RichUtils.toggleLink(
            newEditorState,
            newEditorState.getSelection(),
            entityKey,
        ));
        setLinkInputState({
            isShow: false,
            url: '',
        })
        setTimeout(() => ref.current?.focus(), 0);
    }
    const removeLink = () => {
        const selection = editorState.getSelection();
        if (!selection.isCollapsed()) {
            setEditorState(RichUtils.toggleLink(editorState, selection, null));
        }
    }
    return (
        <Box sx={{ padding: 1, }} /* onClick={() => { ref.current?.focus() }}*/>
            <Box sx={{ padding: 1 }}>
                <Button variant="outlined" sx={{ mr: 1 }} onMouseDown={(evt => {
                    evt.preventDefault()
                    setEditorState(RichUtils.toggleBlockType(editorState, 'header-three'))
                })}>見出し</Button>
                <Button variant="outlined" sx={{ mr: 1 }} onMouseDown={(evt => {
                    evt.preventDefault()
                    setEditorState(RichUtils.toggleBlockType(editorState, 'unordered-list-item'))
                })}>リスト</Button>
                <Button variant="outlined" sx={{ mr: 1 }} onMouseDown={evt => {
                    evt.preventDefault()
                    setEditorState(RichUtils.toggleInlineStyle(editorState, 'BOLD'))
                }}>Bold</Button>
                <Button variant="outlined" sx={{ mr: 1 }} onMouseDown={evt => {
                    evt.preventDefault()
                    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
                }}>下線</Button>
                <Button variant="outlined" sx={{ mr: 1 }} onMouseDown={evt => {
                    evt.preventDefault()
                    setEditorState(RichUtils.toggleInlineStyle(editorState, 'ITALIC'))
                }}>ITALIC</Button>
                <Button variant="outlined" sx={{ mr: 1 }} onMouseDown={evt => {
                    evt.preventDefault()
                    setEditorState(RichUtils.toggleInlineStyle(editorState, 'STRIKETHROUGH'))
                }}>打消し線</Button>
                <Button variant="outlined" sx={{ mr: 1 }} onMouseDown={evt => {
                    evt.preventDefault()
                    setEditorState(RichUtils.toggleInlineStyle(editorState, 'UNDERLINE'))
                }}>UNDERLINE</Button>
                <Button variant="outlined" sx={{ mr: 1 }} onMouseDown={evt => {
                    evt.preventDefault()
                    const selection = editorState.getSelection();
                    if (!selection.isCollapsed()) {
                        const contentState = editorState.getCurrentContent();
                        const startKey = editorState.getSelection().getStartKey();
                        const startOffset = editorState.getSelection().getStartOffset();
                        const blockWithLinkAtBeginning = contentState.getBlockForKey(startKey);
                        const linkKey = blockWithLinkAtBeginning.getEntityAt(startOffset);
                        let url = '';
                        if (linkKey) {
                            const linkInstance = contentState.getEntity(linkKey);
                            url = linkInstance.getData().url;
                        }

                        setLinkInputState({
                            isShow: true,
                            url,
                        })
                        setTimeout(() => refLinkInput.current!.focus(), 0);
                    }

                }}>リンク挿入</Button>
                <Button onMouseDown={evt => {
                    evt.preventDefault()
                    removeLink()
                }}>リンク削除</Button>
                <Button onMouseDown={evt => {
                    evt.preventDefault()
                    setEditorState(EditorState.undo(editorState))
                }} ><Undo />undo</Button>
            </Box>
            <Box sx={{ display: linkInputState.isShow ? 'block' : 'none', mb: 3 }}>
                <TextField
                    type="text"
                    inputRef={refLinkInput}
                    value={linkInputState.url}
                    onChange={handleChangeLinkUrl}
                    size="small"
                    sx={{ width: 200 }}
                    onKeyDown={e => {
                        if (e.which === 13) {
                            e.preventDefault()
                            confirmLink()
                        }
                    }}
                    placeholder="URL"
                />
                <Button variant="contained" size="small" sx={{ ml: 2 }} onClick={evt => {
                    evt.preventDefault()
                    confirmLink()
                }}>OK</Button>
            </Box>
            {label != null && (
                <Box sx={{ fontSize: 0.5 }}>{label}</Box>
            )}
            <Box sx={{ padding: 1, border: 'solid 1px rgba(0,0,0,0.2)' }}>
                <Editor
                    editorState={editorState}
                    onChange={handleChangeEditorState}
                    ref={ref}
                />
            </Box>
        </Box>
    )
})

export default TextEditor

interface Props {
    value: string
    onChange: (value: string) => any
    label?: string | undefined
}


const Link: ComponentType<PropsWithChildren<{ contentState: ContentState, entityKey: string }>> = (props) => {
    const { url } = props.contentState.getEntity(props.entityKey).getData();
    return (
        <a href={url}>
            {props.children}
        </a>
    );
};

function findLinkEntities(contentBlock: ContentBlock, callback: (start: number, end: number) => void, contentState: ContentState) {
    contentBlock.findEntityRanges(
        (character) => {
            const entityKey = character.getEntity();
            return (
                entityKey !== null &&
                contentState.getEntity(entityKey).getType() === 'LINK'
            );
        },
        callback
    );
}

const decorator = new CompositeDecorator([
    {
        strategy: findLinkEntities,
        component: Link,
    },
]);

interface Inline {
    offset: number
    text: string
    link: string
    type: 'link' | 'b' | 'u' | 'i' | 's'
}

import { ComponentType, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { NewsItem } from "sgcms-models"
import { NewsFormViewProps } from "./NewsForm"
import { useServer } from "./providers/server"

const NewsCreate:ComponentType<Props> = ({View}) => {
    const navigate = useNavigate()
    const server = useServer()
    const [value, setValue] = useState<NewsItem>({
        id: undefined,
        title: '',
        text: '',
        date: new Date(),
        isPublic: false,
    })
    if (value == null) {
        return <></>
    }
    const handleSave = async () => {
        await server.createNewsItem(value!)
        alert('下書きに保存しました')
        navigate(`/news`)
    }
    const handlePublish = async () => {
        const id = await server.createNewsItem(value!)
        await server.publishNewsItem(id)
        alert('投稿しました')
        navigate(`/news`)
    }
    return (
        <View
            formProps={{
                value: value,
                setValue: value => setValue(value),
                isPublic: false,
                onSave: handleSave,
                onPublish: handlePublish,
            }}
        />
    )
}

interface Props {
    View: NewsCreateViewType
}

export default NewsCreate

export type NewsCreateViewType = ComponentType<{
    formProps: NewsFormViewProps
}>

import { ComponentType, createContext, PropsWithChildren, ReactElement, useCallback, useContext, useEffect, useState } from "react";
import { Server, Session } from "sgcms-models";

export const SessionProvider: ComponentType<PropsWithChildren<ProviderProps>> = ({ children, server, loginElement }) => {
    const [state, setState] = useState<{ session: Session | undefined, isFetched: boolean }>({
        session: undefined,
        isFetched: false
    })
    const fetchAsync = useCallback(async () => {
        try {
            setState({
                session: await server.getCurrentSession(),
                isFetched: true,
            })
        } catch(error) {
            setState({
                session: undefined,
                isFetched: true,
            })
        }
    }, [server])

    const value: Value = {
        session: state.session,
        isFetched: state.isFetched,
        fetchCurrent: fetchAsync,
    }

    return (
        <context.Provider value={value}>{children}</context.Provider>
    )
}

export const useSession = () => useContext(context)!

interface ProviderProps {
    server: Server
    loginElement: ReactElement
}

interface Value {
    session: Session | undefined
    isFetched: boolean
    fetchCurrent: () => Promise<any>
}

const context = createContext<Value | undefined>(undefined)
export interface Template {
    elements: TemplateElement[]
}

export const elementTypes = ['block', 'blockList', 'table', 'list'] as const

export type ElementType = typeof elementTypes[number]

export interface TemplateElement {
    name: string
    title: string
    type: ElementType
    blockSpec?: BlockSpec
    tableSpec?: TableSpec
    listSpec?: ListSpec
}

export interface BlockSpec {
    readonly numTitle: number
    readonly numTexts: number
    readonly numImages: number
    readonly numButton: number
    readonly form: {
        [key in 'title'|'text'|'image']: {
            title: string
        }[]
    },
}

export interface TableSpec {
    minRow:number
    maxRow:number|undefined
    minCol:number
    maxCol:number|undefined
}

export interface ListSpec {
    elementTypes: ElementType[]
    blockSpec?: BlockSpec
    tableSpec?: TableSpec
    strings?: {
        [key in 'block' | 'table']: string
    },
}

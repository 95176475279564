import { Server } from "./server"
import { Dispatch } from "./common"
import { Company } from "./company"
import { Block, Image, Table } from "./content"
import { Page, PageKind } from "./page"
import { SiteTheme } from "./siteTheme"

export class Site implements SiteData {
    constructor(
        private readonly dispatch: Dispatch<SiteData>,
        private readonly server: Server,
        private readonly data: SiteData
    ) { }

    readonly siteName: string = this.data.siteName
    readonly pageKinds: PageKind[] = this.data.pageKinds

    async addImage(file: Blob): Promise<Image> {
        const id = await this.server.uploadImage(file)
        const image = await this.server.getImage(id)
        return image
    }

    async getPage(kind: PageKind): Promise<Page> {
        return await this.server.getPage(kind)
    }

    async getPages(): Promise<Page[]> {
        return await this.server.getPages()
    }

    async updatePageEnable(kind: PageKind, enable: boolean) {
        await this.server.updatePageEnable(kind, enable)
    }

    async createBlock(block: Block) {
        await this.server.createBlock(block)
    }

    async updateBlock(block: Block) {
        await this.server.updateBlock(block)
    }

    async updateTable(table: Table) {
        await this.server.updateTable(table)
    }

    async getPagePreview(kind: PageKind): Promise<string> {
        return this.server.getPagePreview(kind)
    }

    async getSiteTheme(): Promise<SiteTheme> {
        return this.server.getSiteTheme()
    }

    async updateSiteTheme(siteTheme: SiteTheme): Promise<void> {
        return this.server.updateSiteTheme(siteTheme)
    }

    async getCompany(): Promise<Company> {
        return this.server.getCompany()
    }

    async updateCompany(company: Company): Promise<void> {
        return this.server.updateCompany(company)
    }
}

export interface SiteData {
    readonly siteName: string
    readonly pageKinds: PageKind[]
}

import { Table, Box, TableCell, TableRow } from "@mui/material"
import { ComponentType } from "react"
import { ContactDetailViewType } from "../app/ContactDetail"
import Main from "./Main"

const ContactDetailView: ContactDetailViewType = ({ message }) => {
    return (
        <Main>
            <Table>
                <TableRow>
                    <TableCell component="th">お名前</TableCell>
                    <TableCell>{message.name}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">会社名</TableCell>
                    <TableCell>{message.company}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">メールアドレス</TableCell>
                    <TableCell>{message.email}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">電話番号</TableCell>
                    <TableCell>{message.tel}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">住所</TableCell>
                    <TableCell>{message.address}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell component="th">お問い合わせ内容</TableCell>
                    <TableCell>{message.body}</TableCell>
                </TableRow>
            </Table>
        </Main>
    )

}
export default ContactDetailView

const Row: ComponentType<{}> = ({ children }) => {
    return (
        <Box
            sx={{
                p: 2,
            }}
        >
            {children}
        </Box>
    )
}
import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import Preview from "./Preview"
import { useServer } from "./providers/server"

const PreviewNews = () => {
    const server = useServer()
    const [source, setSource] = useState<string | undefined>(undefined)
    const fetchAsync = useCallback(async () => {
        const html = await server.getNewsPreview()
        return html
        // (wnd as any).document.write(html)
    }, [server])
    useEffect(() => {
        setSource(undefined)
        fetchAsync().then(source => {
            setSource(source)
        })
    }, [fetchAsync])
    return (
        <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999, background: 'inherit' }}>
            {source != null && (
                <Preview
                    source={source}
                />
            )}
        </Box>
    )
}

export default PreviewNews

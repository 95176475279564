import { useCallback, useEffect, useState } from "react"
import { Company } from "sgcms-models"
import CompanyForm from "./CompanyForm"
import { useSite } from "./providers/site"

const CompanyEdit = () => {
    const site = useSite()
    const [company, setCompany] = useState<Company | null>(null)
    const fetchAsync = useCallback(async () => {
        const company = await site.getCompany()
        setCompany(company)
    }, [setCompany, site])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync, setCompany, site])
    const handleSubmit = async () => {
        await site.updateCompany(company!)
        alert('反映しました')
    }
    if (company == null) {
        return <></>
    }
    return (
        <div>
            <CompanyForm
                value={company}
                onChange={setCompany}
                onSubmit={handleSubmit}
            />
        </div>
    )
}

export default CompanyEdit
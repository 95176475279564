import { ComponentType,  } from 'react'
import { FC, ReactElement, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Block, Table, ElementType, TemplateElement, Content } from "sgcms-models"
import { Page, PageKind } from "sgcms-models"
import { BlockSpec, TableSpec } from "sgcms-models"
import { useSite } from "./providers/site"
import { useServer } from './providers/server'

const PageContents: FC<Props> = ({ View }) => {
    const { kind } = useParams()
    const server = useServer()
    const site = useSite()
    const [page, setPage] = useState<Page | undefined>(undefined)
    const [editingState, setEditingState] = useState<EditingState | undefined>(undefined)

    const navigate = useNavigate()

    const fetchAsync = useCallback(async () => {
        const page = await site.getPage(kind as PageKind)
        setPage(page)
    }, [kind, site])

    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])

    if (page == null) {
        return <></>
    }

    const handleClickPreview = async () => {
        // navigate(`/preview/${kind}`)
        window.open(`/preview/${kind}`, 'preview')
    }

    const handleClickDeploy = async () => {
        await server.deployPage(page.kind)
        alert("反映しました")
    }

    const handleClickEdit = (editingState: EditingState) => {
        setEditingState(editingState)
    }

    const getEditBlockSpec = (elem: TemplateElement): BlockSpec | undefined => {
        if (elem.type === 'list') {
            return elem.listSpec!.blockSpec!
        } else if (elem.type === 'blockList') {
            return elem.blockSpec!
        } else if (elem.type === 'block') {
            return elem.blockSpec!
        } else {
            return undefined
        }
    }

    const getEditBlock = (elem: TemplateElement, content: Page['contents'][string]): Block | undefined => {
        if (editingState == null || editingState.contentType !== 'block') {
            return undefined
        }
        const spec = getEditBlockSpec(elem)
        if (editingState.contentId == null) {
            return {
                id: undefined,
                title: '',
                text: '',
                buttons: [],
                images: (() => {
                    const a = []
                    for (let i = 0; i < spec!.numImages; i++) {
                        a.push(undefined)
                    }
                    return a
                })(),
            }
        }
        switch (elem.type) {
            case 'block':
                return content.block
            case 'blockList':
                return content.blockList!.find(v => v.id === editingState.contentId)
            case 'list':
                return content.list!.find(v => v.block?.id === editingState.contentId)?.block
        }
    }



    const renderEditBlock = (BlockForm: BlockFormType) => {
        if (editingState == null) {
            return <></>
        }
        const elem = page.template.elements.find(elm => elm.name === editingState.elementName)!
        const content = page.contents[elem.name]
        const block = getEditBlock(elem, content)
        if (block == null) {
            return <></>
        }
        const spec = getEditBlockSpec(elem)
        if (spec == null) {
            return <></>
        }
        const handleSubmit = async (next: Block) => {
            if (next.id != null) {
                await site.updateBlock(next)
                await fetchAsync()
                setEditingState(undefined)
                return
            }
            if (elem.type === 'blockList') {
                await server.addBlockToBlockList(next, page.kind, elem.name, editingState.afterContentId)
                await fetchAsync()
                setEditingState(undefined)
                return
            }
            if (elem.type === 'list') {
                await server.addBlockToContentList(next, page.kind, elem.name, editingState.afterContentType, editingState.afterContentId)
                await fetchAsync()
                setEditingState(undefined)
                return
            }
        }
        return <BlockForm
            spec={spec}
            value={block}
            onSubmit={handleSubmit}
        />
    }

    const getEditTableSpec = (elem: TemplateElement): TableSpec | undefined => {
        if (elem.type === 'list') {
            return elem.listSpec!.tableSpec!
        } else {
            return undefined
        }
    }

    const getEditTable = (elem: TemplateElement, content: Page['contents'][string]): Table | undefined => {
        if (editingState == null || editingState.contentType !== 'table') {
            return undefined
        }
        if (editingState.contentId == null) {
            return {
                id: undefined,
                headerText: '',
                footerText: '',
                numCols: 5,
                numRows: 3,
                rows: [
                    ["", "", "", "", ""],
                    ["", "", "", "", ""],
                    ["", "", "", "", ""],
                ],
                tableStyle: 'table1',
            }
        }
        switch (elem.type) {
            case 'list':
                return content.list!.find(v => v.table?.id === editingState.contentId)?.table
            default:
                return undefined
        }
    }

    const renderEditTable = (TableForm: TableFormType) => {
        if (editingState == null) {
            return <></>
        }
        const elem = page.template.elements.find(elm => elm.name === editingState.elementName)!
        const content = page.contents[elem.name]
        const table = getEditTable(elem, content)
        if (table == null) {
            return <></>
        }
        const spec = getEditTableSpec(elem)
        if (spec == null) {
            return <></>
        }
        const handleSubmit = async (next: Table) => {
            if (next.id != null) {
                await server.updateTable(next)
                await fetchAsync()
                setEditingState(undefined)
                return
            }
            if (elem.type === 'list') {
                await server.addTableToContentList(next, page.kind, elem.name, editingState.afterContentType, editingState.afterContentId)
                await fetchAsync()
                setEditingState(undefined)
                return
            }
        }
        return <TableForm
            spec={spec}
            value={table}
            onSubmit={handleSubmit}
        />
    }

    return (
        <View
            page={page}
            editingState={editingState}
            onClickDeploy={handleClickDeploy}
            onClickPreview={handleClickPreview}
            onClickEdit={handleClickEdit}
            renderEditBlock={renderEditBlock}
            renderEditTable={renderEditTable}
            onClickRemoveItemFromList={async (item: Content, elemName: string) => {
                if (item.block != null) {
                    await server.removeBlockFromContentList(page.kind, elemName, item.block.id!)
                } else if (item.table != null) {
                    await server.removeTableFromContentList(page.kind, elemName, item.table.id!)
                }
                await fetchAsync()
            }}
            onClickRemoveBlockFromBlockList={async (block: Block, elemName: string) => {
                await server.removeBlockFromBlockList(page.kind, elemName, block.id!)
                await fetchAsync()
            }}
            isShowListContentMoveUp={(item: Content, elemName: string) => {
                const list = page.contents[elemName].list!
                const idx = list.findIndex(v => v === item)!
                return idx > 0
            }}
            isShowListContentMoveDown={(item: Content, elemName: string) => {
                const list = page.contents[elemName].list!
                const idx = list.findIndex(v => v === item)!
                return idx < list.length - 1
            }}
            onClickListContentMoveUp={async (item:Content, elemName:string) => {
                const list = page.contents[elemName].list!
                const idx = list.findIndex(v => v === item)
                await server.swapListContentOrder(page.kind, elemName, idx, idx-1)
                await fetchAsync()
            }}
            onClickListContentMoveDown={async (item:Content, elemName:string) => {
                const list = page.contents[elemName].list!
                const idx = list.findIndex(v => v === item)
                await server.swapListContentOrder(page.kind, elemName, idx, idx+1)
                await fetchAsync()
            }}
        />
    )
}

export default PageContents

interface Props {
    View: PageContentsViewType
}

export type PageContentsViewType = ComponentType<{
    page: Page,
    editingState: EditingState | undefined,
    onClickPreview: () => any,
    onClickDeploy: () => any,
    onClickEdit: (editingContent: EditingState) => any,
    renderEditBlock: (BlockForm: BlockFormType) => ReactElement
    renderEditTable: (TableForm: TableFormType) => ReactElement
    onClickRemoveItemFromList: (item: Content, elemName: string) => any
    onClickRemoveBlockFromBlockList: (block: Block, elemName: string) => any
    isShowListContentMoveUp: (item: Content, elemNam: string) => boolean
    isShowListContentMoveDown: (item: Content, elemName: string) => boolean
    onClickListContentMoveUp: (item: Content, elemName: string) => any
    onClickListContentMoveDown: (item: Content, elemName: string) => any
}>

export type EditingState = {
    elementName: string,
    contentType: ElementType,
    contentId?: string | undefined,
    afterContentId?: string | undefined,
    afterContentType?: ElementType | undefined,
}

export type BlockFormType = ComponentType<{
    value: Block
    spec: BlockSpec,
    onSubmit: (block: Block) => any
}>

export type TableFormType = ComponentType<{
    value: Table,
    spec: TableSpec,
    onSubmit: (value: Table) => any
}>

import { TextField } from "@mui/material"
import { ChangeEvent, ComponentType } from "react"

const TextInput:ComponentType<Props> = ({value, onChange, label}) => {
    const handleChange = (evt:ChangeEvent<HTMLInputElement>) => {
        onChange(evt.target.value)
    }
    return (
        <TextField variant="outlined" value={value} onChange={handleChange} label={label} />
    )
}

export default TextInput

interface Props {
    value:string
    onChange: (v:string) => any
    label: string
}

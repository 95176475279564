import { Box, Button, MenuItem, TextField, Typography } from "@mui/material"
import { ChangeEvent, useCallback, useEffect, useState } from "react"
import { SiteTheme } from "sgcms-models"
import { useServer } from "./providers/server"
import { useSite } from "./providers/site"

const Themes = () => {
    const server = useServer()
    const site = useSite()
    const [theme, setTheme] = useState<SiteTheme | undefined>(undefined)
    const fetchAsync = useCallback(async () => {
        const theme = await site.getSiteTheme()
        setTheme(theme)
    }, [site])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    if (theme == null) {
        return <></>
    }
    const handleChange = (key: 'colorTheme' | 'mainVisualCPStyle') => async (evt: ChangeEvent<HTMLInputElement>) => {
        await site.updateSiteTheme({
            ...theme,
            [key]: evt.target.value,
        })
        await fetchAsync()
    }
    const handleClickPreview = async () => {
        const html = await site.getPagePreview('top')
        const wnd = window.open("", "", "_blank");
        (wnd as any).document.write(html)
    }
    const handleClickDeploy = async() => {
        await server.deployAll()
        alert('反映しました')
    }
    return (
        <Box sx={{ padding: 2 }}>
            <Box sx={{ mb: 1, padding: 1 }}>
                <Button variant="contained" color="primary" onClick={handleClickPreview}>プレビュー</Button>
                <Button variant="contained" color="primary" onClick={handleClickDeploy} sx={{ml: 1}}>全ページに反映する</Button>
            </Box>
            <Box sx={{ padding: 1, mb: 1 }}>
                <Typography variant="h4">テーマカラー</Typography>
                <TextField select value={theme.colorTheme} onChange={handleChange('colorTheme')}>
                    <MenuItem value="default">テーマ（紺）</MenuItem>
                    <MenuItem value="red">テーマ（赤）</MenuItem>
                    <MenuItem value="green">テーマ（緑）</MenuItem>
                    <MenuItem value="black">テーマ（黒）</MenuItem>
                </TextField>
            </Box>
            <Box sx={{ padding: 1, mb: 1 }}>
                <Typography variant="h4">メインビジュアル</Typography>
                <Typography variant="h6">キャッチコピー位置</Typography>

                <TextField select value={theme.mainVisualCPStyle} onChange={handleChange('mainVisualCPStyle')}>
                    <MenuItem value="none">キャッチコピーなし</MenuItem>
                    <MenuItem value="lead1">左下</MenuItem>
                    <MenuItem value="lead2">中央</MenuItem>
                </TextField>
            </Box>
        </Box>
    )
}

export default Themes
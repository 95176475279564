import { Block, Content, Table } from "./content";
import { Template } from "./template";

export const pageKinds = ['top', 'company', 'service', 'news', 'price'] as const

export type PageKind = typeof pageKinds[number]

export interface Page {
    kind: PageKind
    title: string
    enable: boolean
    template: Template
    contents: {
        [name:string]: {
            block?: Block | undefined,
            blockList?: Block[] | undefined,
            table?: Table | undefined,
            list?: Content[] | undefined,
        },
    },
}

export interface PageServer {
    getPage(kind:PageKind):Promise<Page>
    getPages():Promise<Page[]>
    updatePageEnable(kind:PageKind, enable:boolean):Promise<void>
}

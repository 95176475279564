import { ChangeEvent, ComponentType, FC, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Page, PageKind } from "sgcms-models"
import { useSite } from "./providers/site"
import { TenantInfo } from "sgcms-models"
import { useServer } from "./providers/server"

const Pages: FC<Props> = ({ View }) => {
    const navigate = useNavigate()
    const server = useServer()
    const site = useSite()
    const [pages, setPages] = useState<Page[] | undefined>(undefined)
    const [tenant, setTenant] = useState<TenantInfo | undefined>(undefined)
    const [basicAuthState, setBasicAuthState] = useState<BasicAuthState>({ isShowBasicAuthForm: false, isWaitingForSetPassword: false, form: { user: '', password: '' } })
    const fetchAsync = useCallback(async () => {
        setPages(await site.getPages())
        setTenant(await server.getTenanInfo())
    }, [server, site])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    const handleClickEditContents = (kind: PageKind) => {
        navigate(`/pages/${kind}/contents`)
    }
    const handleChangePageEnable = async (kind: PageKind, value: boolean) => {
        await site.updatePageEnable(kind, value)
        await fetchAsync()
    }
    if (pages == null || tenant == null) {
        return <></>
    }
    return (
        <View
            pages={pages}
            tenant={tenant}
            onClickEdit={page => handleClickEditContents(page.kind)}
            onChangeEnable={(page, value) => handleChangePageEnable(page.kind, value)}
            onChangeIsPublic={async b => {
                await server.updateIsPublic(b)
                await fetchAsync()
                if (b) {
                    alert('公開しました')
                } else {
                    alert('非公開にしました')
                }
            }}
            onChangeIsBasicAuth={async (b) => {
                if (b === true) {
                    if (tenant.basicAuthUser !== "") {
                        await server.updateIsBasicAuth(true)
                        await fetchAsync()
                        alert('パスワード認証を設定しました')
                    } else {
                        setBasicAuthState({
                            isShowBasicAuthForm: true,
                            isWaitingForSetPassword: true,
                            form: {
                                user: '',
                                password: '',
                            },
                        })
                    }
                } else {
                    await server.updateIsBasicAuth(false)
                    await fetchAsync()
                    alert("パスワード認証を解除しました")
                }
            }}
            onSubmitBasicAuthForm={async () => {
                const { user, password } = basicAuthState.form
                await server.updateBasicAuthPassword(user, password)
                if (!basicAuthState.isWaitingForSetPassword) {
                    await fetchAsync()
                    alert("ユーザーとパスワードを変更しました")
                    setBasicAuthState(state => ({
                        ...state,
                        isShowBasicAuthForm: false,
                        isWaitingForSetPassword: false,
                    }))
                    return
                }
                await server.updateIsBasicAuth(true)
                await fetchAsync()
                alert('パスワード認証を設定しました')
                setBasicAuthState(state => ({
                    ...state,
                    isWaitingForSetPassword: false,
                    isShowBasicAuthForm: false,
                }))
            }}
            onChangeBasicAuthForm={(name, value) => {
                setBasicAuthState(state => ({
                    ...state,
                    form: {
                        ...state.form,
                        [name]: value,
                    },
                }))
            }}
            isShowBasicAuthForm={basicAuthState.isShowBasicAuthForm}
            basicAuthForm={basicAuthState.form}
            onCancelBasicAuthForm={() => {
                setBasicAuthState(state => ({
                    ...state,
                    isShowBasicAuthForm: false,
                    isWaitingForSetPassword: false,
                }))
            }}
            onShowBasicAuthForm={() => {
                setBasicAuthState(state => ({
                    ...state,
                    isShowBasicAuthForm: true,
                    isWaitingForSetPassword: false,
                    form: {
                        user: tenant.basicAuthUser,
                        password: '',
                    },
                }))
            }}
        />
    )
}

export default Pages

interface Props {
    View: PagesViewType
}

export type PagesViewType = ComponentType<{
    pages: Page[]
    tenant: TenantInfo
    onChangeEnable: (page: Page, value: boolean) => any
    onClickEdit: (page: Page) => any
    onChangeIsPublic: (isPublic: boolean) => any
    onChangeIsBasicAuth: (isBasicAuth: boolean) => any
    onSubmitBasicAuthForm: () => any
    onChangeBasicAuthForm: (name: keyof BasicAuthForm, value: string) => any
    isShowBasicAuthForm: boolean
    basicAuthForm: BasicAuthForm
    onCancelBasicAuthForm: () => any
    onShowBasicAuthForm: () => any
}>

export interface BasicAuthForm {
    user: string
    password: string
}

interface BasicAuthState {
    isWaitingForSetPassword: boolean
    isShowBasicAuthForm: boolean
    form: BasicAuthForm
}

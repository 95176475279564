import { Box } from "@mui/material";
import { ComponentType, PropsWithChildren } from "react";

const FormRow:ComponentType<Props> = ({children}) => {
    return (
        <Box sx={{padding: 1}}>{children}</Box>
    )
}

export default FormRow

type Props = PropsWithChildren<{}>
import { NewsModifyViewType } from "../app/NewsModify";
import NewsFormView from "./NewsFormView";

const NewsModifyView:NewsModifyViewType = ({formProps}) => {
    return (
        <NewsFormView
            {...formProps}
        />
    )
}

export default NewsModifyView
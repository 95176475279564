export interface Block {
    id: string | undefined
    title: string
    text: string
    images: (Image | undefined)[]
    buttons: Button[]
}

export interface Button {
    label: string
}

export interface Image {
    id: string
    url: string
}

export const tableStyles = ['table1', 'table2', 'table3'] as const

export type TableStyle = typeof tableStyles[number]

export interface Table {
    id: string|undefined
    tableStyle: TableStyle
    numRows: number,
    numCols: number,
    rows: string[][]
    headerText: string
    footerText: string
}

export interface Content {
    table?: Table|undefined
    block?: Block|undefined
}

export interface ContentServer {
    getImage(id: string): Promise<Image>
    uploadImage(file: Blob): Promise<string>
    deleteImage(id: string): Promise<void>
    getBlock(id: string): Promise<Block>
    createBlock(block: Block): Promise<string>
    updateBlock(block: Block): Promise<void>
    deleteBlock(block: Block): Promise<void>
    getTable(id: string): Promise<Table>
    createTable(table: Table):Promise<string>
    updateTable(table: Table):Promise<void>
    deleteTable(id: string):Promise<void>
}

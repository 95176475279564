import { ElementType, PageKind, SearchResult } from "sgcms-models";
import { Company } from "sgcms-models";
import { ContactFormSettings } from "sgcms-models";
import { Image, Block, Table } from "sgcms-models";
import { SearchNewsOptions, NewsItem } from "sgcms-models";
import { Page } from "sgcms-models";
import { Server } from "sgcms-models";
import { SiteTheme } from "sgcms-models";
import nodeFetch from 'node-fetch'
import { Session } from "sgcms-models";
import { parseJSON } from 'date-fns'
import { ContactMessage } from "sgcms-models";
import { SearchContactMessagesOptions } from "sgcms-models";
import { TenantInfo } from "sgcms-models/build/tenantInfo";

export class ApiServer implements Server {
    constructor(
        private readonly fetch: (typeof window.fetch | typeof nodeFetch),
        private readonly urlbase: string) { }



    async getCompany(): Promise<Company> {
        return this.get(`/api/editor/company`)
    }
    updateCompany(company: Company): Promise<void> {
        return this.update(`/api/editor/company`, company)
    }
    updateContactFormSettings(settings: ContactFormSettings): Promise<void> {
        throw new Error("Method not implemented.");
    }
    getContactFormSettings(): Promise<ContactFormSettings> {
        throw new Error("Method not implemented.");
    }
    getImage(id: string): Promise<Image> {
        return this.get(`/api/editor/images/${id}`)
    }
    async uploadImage(file: Blob): Promise<string> {
        const resp = await this.fetch(`/api/editor/images/upload`, {
            method: "POST",
            body: file,
            headers: {
                "Content-Type": file.type,
            }
        })
        if (!resp.ok) {
            throw Error('api error')
        }
        return (await resp.json()).id
    }
    deleteImage(id: string): Promise<void> {
        throw new Error("Method not implemented.");
    }
    getBlock(id: string): Promise<Block> {
        return this.get(`/api/editor/bocks/${id}`)
    }
    createBlock(block: Block): Promise<string> {
        return this.create("/api/editor/blocks", block)
    }
    updateBlock(block: Block): Promise<void> {
        return this.update(`/api/editor/blocks/${block.id}`, block)
    }
    deleteBlock(block: Block): Promise<void> {
        throw new Error("Method not implemented.");
    }
    getTable(id: string): Promise<Table> {
        return this.get(`/api/editor/tables/${id}`)
    }
    createTable(table: Table): Promise<string> {
        return this.create(`/api/editor/tables`, table)
    }
    updateTable(table: Table): Promise<void> {
        return this.update(`/api/editor/tables/${table.id}`, table)
    }
    deleteTable(id: string): Promise<void> {
        return this.delete(`/api/editor/tables/${id}`)
    }
    async searchNewsItems(opts: SearchNewsOptions): Promise<SearchResult<NewsItem>> {
        const q = new URLSearchParams()
        q.set('page', `${opts.page}`)
        q.set('perPage', `${opts.perPage}`)
        const url = `/api/editor/news-articles?${q.toString()}`
        const resp = await this.fetch(url)
        if (!resp.ok) {
            throw new Error("api error")
        }
        const obj = await resp.json()
        return {
            ...obj,
            list: obj.list.map((v: NewsItem) => ({ ...v, date: parseJSON(v.date) })),
        }
    }

    async getNewsItem(id: string): Promise<NewsItem> {
        const resp = await this.fetch(`/api/editor/news-articles/${id}`)
        if (!resp.ok) {
            throw new Error('api error')
        }
        return await resp.json()
    }

    async deleteNewsItem(id: string): Promise<void> {
        const resp = await this.fetch(`/api/editor/news-articles/${id}`, {
            method: 'DELETE',
        })
        if (!resp.ok) {
            throw new Error('api error')
        }
    }
    async publishNewsItem(id: string): Promise<void> {
        const resp = await this.fetch(`/api/editor/news-articles/${id}/publish`, {
            method: 'POST',
        })
        if (!resp.ok) {
            throw new Error('api error')
        }
    }

    async createNewsItem(newsItem: NewsItem): Promise<string> {
        const resp = await this.fetch(`/api/editor/news-articles`, {
            method: 'POST',
            body: JSON.stringify(newsItem),
        })
        if (!resp.ok) {
            throw new Error("api error")
        }
        return (await resp.json()).id
    }

    async updateNewsItem(newsItem: NewsItem): Promise<void> {
        const resp = await this.fetch(`/api/editor/news-articles/${newsItem.id}`, {
            method: 'PUT',
            body: JSON.stringify(newsItem),
        })
        if (!resp.ok) {
            throw new Error("api error")
        }
    }

    async searchContactMessages(opts: SearchContactMessagesOptions): Promise<SearchResult<ContactMessage>> {
        const q = new URLSearchParams()
        q.set('page', `${opts.page}`)
        q.set('perPage', `${opts.perPage}`)
        const url = `/api/editor/contact/messages?${q.toString()}`
        const resp = await this.fetch(url)
        if (!resp.ok) {
            throw new Error("api error")
        }
        const obj = await resp.json()
        return {
            ...obj,
            list: obj.list.map((v: ContactMessage) => ({ ...v, createdAt: parseJSON(v.createdAt) })),
        }
    }

    async getContactMessage(id: string): Promise<ContactMessage> {
        const resp = await this.fetch(`/api/editor/contact/messages/${id}`)
        if (!resp.ok) {
            throw new Error('api error')
        }
        return await resp.json()
    }


    async getPage(kind: "top" | "company" | "service" | "news" | "price"): Promise<Page> {
        const resp = await this.fetch(this.getEndpoint(`/api/editor/pages/${kind}`))
        if (!resp.ok) {
            throw Error('api error')
        }
        const obj: any = await resp.json()
        return obj as Page
    }
    async getPages(): Promise<Page[]> {
        const resp = await this.fetch(this.getEndpoint('/api/editor/pages'))
        if (!resp.ok) {
            throw Error('api error')
        }
        const obj: any = await resp.json()
        return obj as Page[]
    }
    async updatePageEnable(kind: "top" | "company" | "service" | "news" | "price", enable: boolean): Promise<void> {
        const resp = await this.fetch(this.getEndpoint(`/api/editor/pages/${kind}/update-enable`), {
            method: 'POST',
            body: JSON.stringify({ enable: enable }),
        })
        if (!resp.ok) {
            throw Error('api error')
        }
    }


    async addBlockToBlockList(block: Block, pageKind: PageKind, elementName: string, afterContentId: string | undefined): Promise<string> {
        const blockId = await this.createBlock(block)
        const resp = await this.fetch(`/api/editor/pages/${pageKind}/contents/block-list/${elementName}/blocks`, {
            method: "POST",
            body: JSON.stringify({
                blockId,
                afterContentId,
            }),
        })
        if (!resp.ok) {
            throw new Error("error addBlockToContentList")
        }
        return blockId
    }

    async removeBlockFromBlockList(pageKind: PageKind, elementName: string, blockId: string): Promise<void> {
        return this.delete(`/api/editor/pages/${pageKind}/contents/block-list/${elementName}/blocks/id=${blockId}`)
    }

    async addBlockToContentList(block: Block, pageKind: PageKind, elementName: string, afterContentType: "block" | "blockList" | "table" | "list" | undefined, afterContentId: string | undefined): Promise<string> {
        const blockId = await this.createBlock(block)
        const resp = await this.fetch(`/api/editor/pages/${pageKind}/contents/list/${elementName}/blocks`, {
            method: "POST",
            body: JSON.stringify({
                blockId,
                afterContentId,
                afterContentType,
            }),
        })
        if (!resp.ok) {
            throw new Error("error addBlockToContentList")
        }
        return blockId
    }

    async removeBlockFromContentList(pageKind: PageKind, elementName: string, blockId: string): Promise<void> {
        return this.delete(`/api/editor/pages/${pageKind}/contents/list/${elementName}/blocks/id=${blockId}`)
    }
    async addTableToContentList(table: Table, pageKind: PageKind, elementName: string, afterContentType: ElementType | undefined, afterContentId: string | undefined): Promise<string> {
        const tableId = await this.createTable(table)
        const resp = await this.fetch(`/api/editor/pages/${pageKind}/contents/list/${elementName}/tables`, {
            method: "POST",
            body: JSON.stringify({
                tableId,
                afterContentId,
                afterContentType,
            }),
        })
        if (!resp.ok) {
            throw new Error("error addTableToContentList")
        }
        return tableId
    }

    async removeTableFromContentList(pageKind: PageKind, elementName: string, tableId: string): Promise<void> {
        return await this.delete(`/api/editor/pages/${pageKind}/contents/list/${elementName}/tables/id=${tableId}`)
    }

    async swapListContentOrder(pageKind: PageKind, elementName: string, indexFrom: number, indexTo: number): Promise<void> {
        const resp = await this.fetch(`/api/editor/pages/${pageKind}/contents/list/${elementName}/swap-order`, {
            method: 'POST',
            body: JSON.stringify({
                indexFrom,
                indexTo,
            }),
        })
        if (!resp.ok) {
            throw new Error("error swapListContentOrder")
        }
    }

    async getSiteTheme(): Promise<SiteTheme> {
        return this.get(`/api/editor/site-theme`)
    }
    async updateSiteTheme(siteTheme: SiteTheme): Promise<void> {
        return this.update(`/api/editor/site-theme`, siteTheme)
    }

    async getPagePreview(kind: PageKind): Promise<string> {
        const resp = await this.fetch(`/api/editor/pages/${kind}/preview`, {
            headers: {
                "Accept": "application/binary",
            }
        })
        if (!resp.ok) {
            throw Error("preview failed")
        }
        return await resp.text()
    }

    async getNewsItemPreview(newsItemId: string): Promise<string> {
        const resp = await this.fetch(`/api/editor/news-articles/${newsItemId}/preview`, {
            headers: {
                "Accept": "application/binary",
            }
        })
        if (!resp.ok) {
            throw Error("preview failed")
        }
        return await resp.text()
    }

    async getNewsPreview(): Promise<string> {
        const resp = await this.fetch(`/api/editor/news-articles/preview`, {
            headers: {
                "Accept": "application/binary",
            }
        })
        if (!resp.ok) {
            throw Error("preview failed")
        }
        return await resp.text()
    }

    async getContactPreview(): Promise<string> {
        const resp = await this.fetch(`/api/editor/contact/preview`, {
            headers: {
                "Accept": "application/binary",
            }
        })
        if (!resp.ok) {
            throw Error("preview failed")
        }
        return await resp.text()
    }

    async deployPage(kind: PageKind): Promise<void> {
        const resp = await this.fetch(`/api/editor/pages/${kind}/deploy`, {
            method: 'POST',
        })
        if (!resp.ok) {
            throw Error("deploy failed")
        }
        return

    }

    async deployAll(): Promise<void> {
        const resp = await this.fetch(`/api/editor/deploy-all`, {
            method: 'POST',
        })
        if (!resp.ok) {
            throw Error("deploy failed")
        }
        return
    }

    async uploadPrivacyFile(file: Blob): Promise<string> {
        const resp = await this.fetch(`/api/editor/legal-files/privacy`, {
            method: 'POST',
            body: file,
            headers: {
                "Content-Type": file.type,
            }
        })
        return (await resp.json()).url
    }
    async uploadTermsFile(file: Blob): Promise<string> {
        const resp = await this.fetch(`/api/editor/legal-files/terms`, {
            method: 'POST',
            body: file,
            headers: {
                "Content-Type": file.type,
            }
        })
        return (await resp.json()).url
    }

    async getCurrentSession(): Promise<Session> {
        const resp = await this.fetch(`/api/editor/sessions/current`)
        if (!resp.ok) {
            throw new Error("error getCurrentSession")
        }
        return await resp.json()
    }

    async login(email: string, password: string): Promise<void> {
        const resp = await this.fetch(`/api/editor/sessions`, {
            method: 'POST',
            body: JSON.stringify({
                email,
                password,
            }),
        })
        if (!resp.ok) {
            throw new Error("error login")
        }
        return
    }

    async logout(): Promise<void> {
        const resp = await this.fetch(`/api/editor/sessions/current`, {
            method: 'DELETE',
        })
        if (!resp.ok) {
            throw new Error("error logout")
        }
        return
    }

    async getPagesByTenantId(tenantId: string): Promise<Page[]> {
        const resp = await this.fetch(this.getEndpoint(`/api/tmpl/tenants/${tenantId}/pages`))
        if (!resp.ok) {
            throw Error('api error')
        }
        const obj: any = await resp.json()
        return obj as Page[]
    }

    async getPageByTenantId(tenantId: string, kind: PageKind): Promise<Page> {
        const resp = await this.fetch(this.getEndpoint(`/api/tmpl/tenants/${tenantId}/pages/${kind}`))
        if (!resp.ok) {
            throw Error('api error')
        }
        const obj: any = await resp.json()
        return obj as Page

    }
    async getCompanyByTenantId(tenantId: string): Promise<Company> {
        return this.get(`/api/tmpl/tenants/${tenantId}/company`)
    }

    async getSiteThemeByTenantId(tenantId: string): Promise<SiteTheme> {
        return this.get(`/api/tmpl/tenants/${tenantId}/site-theme`)
    }

    async searchNewsItemsByTenantId(tenantId: string, opts: SearchNewsOptions): Promise<SearchResult<NewsItem>> {
        const q = new URLSearchParams()
        q.set('page', `${opts.page}`)
        q.set('perPage', `${opts.perPage}`)
        const url = this.getEndpoint(`/api/tmpl/tenants/${tenantId}/news-articles?${q.toString()}`)
        const resp = await this.fetch(url)
        if (!resp.ok) {
            throw new Error("api error")
        }
        const obj = await resp.json()
        return {
            ...obj,
            list: obj.list.map((v: NewsItem) => ({ ...v, date: parseJSON(v.date) })),
        }
    }

    async getNewsItemByTenantId(tenantId: string, id: string): Promise<NewsItem> {
        const url = this.getEndpoint(`/api/tmpl/tenants/${tenantId}/news-articles/${id}`)
        const resp = await this.fetch(url)
        if (!resp.ok) {
            throw new Error('api error')
        }
        const obj = await resp.json()
        return {
            ...obj,
            date: parseJSON(obj.date),
        }
    }

    async deployContactPage(): Promise<void> {
        const resp = await this.fetch(`/api/editor/contact/deploy`, {
            method: 'POST',
        })
        if (!resp.ok) {
            throw Error("deploy failed")
        }
        return
    }

    async getTenanInfo(): Promise<TenantInfo> {
        const resp = await this.fetch(`/api/editor/tenant`, {
            method: "GET",
        })
        if (!resp.ok) {
            throw new Error("getTenantInfo failed")
        }
        return await resp.json()
    }

    async updateIsPublic(b: boolean): Promise<void> {
        const s = b ? "yes" : "no"
        const resp = await this.fetch(`/api/editor/tenant/is-public/${s}`, {
            method: "POST",
        })
        if (!resp.ok) {
            throw new Error("updateIsPublic failed")
        }
    }

    async updateIsBasicAuth(b: boolean): Promise<void> {
        const s = b ? "yes" : "no"
        const resp = await this.fetch(`/api/editor/tenant/is-basicauth/${s}`, {
            method: "POST",
        })
        if (!resp.ok) {
            throw new Error("updateBasicAuth failed")
        }
    }

    async updateBasicAuthPassword(user: string, password: string): Promise<void> {
        const resp = await this.fetch(`/api/editor/tenant/basicauth/set-password`, {
            method: "POST",
            body: JSON.stringify({
                user,
                password,
            }),
        })
        if (!resp.ok) {
            throw new Error("updateBasicAuthpassword failed")
        }
    }

    private async create<T>(path: string, t: T): Promise<string> {
        const resp = await this.fetch(this.getEndpoint(path), {
            method: 'POST',
            body: JSON.stringify(t),
        })
        if (!resp.ok) {
            throw Error('api error')
        }
        const obj: any = await resp.json()
        return obj.id as string
    }

    private async update<T>(path: string, t: T): Promise<void> {
        const resp = await this.fetch(this.getEndpoint(path), {
            method: 'PUT',
            body: JSON.stringify(t),
        })
        if (!resp.ok) {
            throw Error('api error')
        }
        return
    }

    private async get<T>(path: string): Promise<T> {
        const resp = await this.fetch(this.getEndpoint(path))
        if (!resp.ok) {
            throw Error('api error')
        }
        return (await resp.json()) as T
    }

    private async delete(path: string): Promise<void> {
        const resp = await this.fetch(this.getEndpoint(path), {
            method: 'DELETE',
        })
        if (!resp.ok) {
            throw Error('api error')
        }
    }


    private getEndpoint(path: string): string {
        // return `http://localhost:8000${path}`
        return `${this.urlbase}${path}`
    }

}

import { MenuItem, TextField } from "@mui/material"
import { ChangeEvent, ComponentType } from "react"

const Select: ComponentType<Props> = ({value, label, items, onChange }) => {
    const renderItems = () => {
        return items.map(item => (
            <MenuItem key={item.value} value={item.value}>{item.label}</MenuItem>
        ))
    }
    const handleChange = (evt:ChangeEvent<HTMLInputElement>) => onChange(evt.target.value)
    return (
        <TextField
            label={label}
            value={value}
            select
            onChange={handleChange}
        >
            {renderItems()}
        </TextField>
    )
}

export default Select

interface Props {
    value: string
    label: string
    items: SelectItem[]
    onChange: (v:string) => any
}

type SelectItem = {
    value: string
    label?: string
}

import React, { ChangeEvent, FC, FormEvent, Fragment, ReactElement, useState } from "react"
import { Block, Image } from "sgcms-models"
import { BlockSpec } from "sgcms-models"
import ImagePicker from "../app/ImagePicker"
import { Box, Button, TextField, Typography } from '@mui/material'
import TextEditor from "./TextEditor"
import { BlockFormType } from "../app/PageContents"

const BlockForm: BlockFormType = ({ value: initialValue, spec, onSubmit }) => {
    const [value, setValue] = useState(initialValue)
    const handleSubmit = (evt: FormEvent) => {
        evt.preventDefault()
        onSubmit(value)
    }
    const handleChangeText = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(value => ({ ...value, text: evt.target.value }))
    }
    const handleChangeTextEditor = (text:string) => {
        setValue(value => ({...value, text}))
    }
    const handleChangeTitle = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setValue(value => ({ ...value, title: evt.target.value }))
    }
    const handleRemoveImage = (idx: number) => () => {
        setValue(value => ({
            ...value,
            images: value.images.map((v, i) => i === idx ? undefined : v),
        }))
    }
    const handleUpoadImage = (idx: number) => (image: Image) => {
        setValue(value => ({
            ...value,
            images: value.images.map((v, i) => i === idx ? image : v),
        }))

    }
    const renderImages = () => {
        const a: ReactElement[] = []
        for (let i = 0; i < spec.numImages; i++) {
            a.push(
                <Box key={`${i}`} sx={{ padding: 1 }}>
                    <Typography variant="h6">{spec.form.image[i].title}</Typography>
                    <ImagePicker
                        image={value.images[0]}
                        onUpload={handleUpoadImage(i)}
                        onDelete={handleRemoveImage(i)}
                    />
                </Box>
            )
        }
        return a
    }
    return (
        <form onSubmit={handleSubmit}>
            {spec.numTitle > 0 && (
                <Box sx={{ padding: 1 }}>
                    <Typography variant="h6">{spec.form.title[0].title}</Typography>
                    <TextField value={value.title} onChange={handleChangeTitle} variant="outlined" rows={5} fullWidth multiline />
                </Box>
            )}
            {spec.numTexts > 0 && (
                <Box sx={{ padding: 1 }}>
                    <Typography variant="h6">{spec.form.text[0].title}</Typography>
                    {/*
                    <TextField value={value.text} onChange={handleChangeText} variant="outlined" rows={10} fullWidth multiline />
                    */}
                    <TextEditor value={value.text} onChange={handleChangeTextEditor} />
                </Box>
            )}
            {renderImages()}
            <Box sx={{ padding: 1, mb: 1 }}>
                <Button type="submit" color="secondary" variant="contained">保存</Button>
            </Box>
        </form>
    )
}

export default BlockForm


import { createContext, FC, useContext, useMemo, useState } from "react";
import { Server } from "sgcms-models";
import { Site, SiteData } from "sgcms-models";

export const SiteProvider: FC<ProviderProps> = ({ server, children }) => {
    const [siteData, setSiteData] = useState<SiteData>({
        pageKinds: ['top'],
        siteName: 'サンプルサイト',
    })
    const site = useMemo(() => {
        return new Site(setSiteData, server, siteData)
    }, [server, siteData])
    return (
        <context.Provider value={{
            site,
        }}>
            {children}
        </context.Provider>
    )
}

export const useSite = (): Site => {
    return useContext(context)!.site
}

const context = createContext<ContextValue | undefined>(undefined)

interface ContextValue {
    site: Site
}

interface ProviderProps {
    server: Server
}

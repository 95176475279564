import { ComponentType, Fragment } from 'react'
import { Box, Button, Paper, Typography } from "@mui/material"
import { FC, ReactElement, useCallback, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { Block, Table, TableStyle } from "sgcms-models"
import { Page, PageKind } from "sgcms-models"
import { BlockSpec, TableSpec } from "sgcms-models"
import BlockForm from "./BlockForm"
import TableForm from "./TableForm"
import htmlParse, { domToReact } from 'html-react-parser'

import { PageContentsViewType } from "../app/PageContents"

const PageContentsView: PageContentsViewType = ({ page, editingState, onClickPreview, onClickDeploy, onClickEdit, renderEditBlock, renderEditTable, onClickRemoveItemFromList, onClickRemoveBlockFromBlockList, isShowListContentMoveDown, isShowListContentMoveUp, onClickListContentMoveDown, onClickListContentMoveUp }) => {
    const renderBlockContent = (block: Block, spec: BlockSpec) => {
        return (
            <>
                {spec.numTitle > 0 && (
                    <>
                        <Typography variant="h6" sx={{ fontSize: "1.2em", color: "rgba(0,0,0,0.5)" }} >{spec.form.title[0].title}</Typography>
                        <pre>{block.title}</pre>
                    </>
                )}
                {spec.numTexts > 0 && (
                    <>
                        <Typography variant="h6" sx={{ fontSize: "1.2em", color: "rgba(0,0,0,0.5)" }}>{spec.form.text[0].title}</Typography>
                        <pre>{htmlParse(block.text)}</pre>
                    </>
                )}
                {spec.numImages > 0 && (
                    <>
                        <Box>
                            <Typography variant="h6" sx={{ fontSize: "1.2em", color: "rgba(0,0,0,0.5)" }}>{spec.form.image[0].title}</Typography>
                            <img style={{ width: 200 }} src={block.images[0]!.url} alt="" />
                        </Box>
                    </>
                )}
            </>
        )
    }
    const renderBlock = (elem: TemplateElementItem) => {
        const spec = elem.blockSpec!
        let block = page.contents[elem.name]?.block
        if (block == null) {
            block = {
                id: "",
                buttons: [],
                images: [],
                text: "",
                title: "",
            }
        }
        if (editingState != null && editingState.elementName === elem.name) {
            return (
                <Box component="section" sx={{ padding: 1 }}>
                    <Typography variant="h4">{elem.title}</Typography>
                    {renderEditBlock(BlockForm)}
                </Box>
            )
        }
        return (
            <Box component="section" sx={{ padding: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                    <Typography variant="h4">{elem.title}</Typography>
                    <Button type="button" variant="text" disabled={editingState != null} onClick={() => onClickEdit({ elementName: elem.name, contentType: 'block', contentId: block!.id })}>編集</Button>
                </Box>
                {renderBlockContent(block, spec)}
            </Box>
        )
    }

    const renderBlockList = (elem: TemplateElementItem) => {
        const spec = elem.blockSpec!
        const blockList = page.contents[elem.name].blockList!
        return (
            <Box component="section" sx={{ padding: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                    <Typography variant="h4">{elem.title}</Typography>
                </Box>
                {blockList.map((block, idx) => {
                    if (editingState != null && editingState.elementName === elem.name && editingState.contentId === block.id) {
                        return renderEditBlock(BlockForm)
                    }
                    if (editingState != null && editingState.elementName === elem.name && editingState.contentId == null && editingState.afterContentId === block.id) {
                        return renderEditBlock(BlockForm)
                    }
                    return (
                        <Fragment key={`idx${idx}`}>
                            <Box sx={{ mb: 2 }}>
                                {renderBlockContent(block, spec)}
                                <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'block', contentId: block.id })}>編集</Button>
                                <Button variant="text" onClick={() => onClickRemoveBlockFromBlockList(block, elem.name)}>削除</Button>
                                <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'block', contentId: undefined, afterContentId: block.id })}>上に追加 </Button>
                                <hr />
                            </Box>
                        </Fragment>
                    )
                })}
                {editingState != null && editingState.elementName === elem.name && editingState.contentId == null && editingState.afterContentId == null && (
                    renderEditBlock(BlockForm)
                )}
                <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'block', contentId: undefined, afterContentId: undefined })}>追加 </Button>
            </Box>
        )
    }

    const renderTableContent = (table: Table, spec: TableSpec) => {
        const renderCol = (row: number, col: number, colValue: string) => {
            /*
            if (selectedCol != null && row === selectedCol[0] && col === selectedCol[1]) {
                return (
                    <td>
                        <input type="text" value={value} onChange={handleChangeCol} />
                    </td>
                )
            }
            return <td onClick={handleClickCol(row, col)}>{value}</td>
            */
            const backgroundColor = () => {
                switch (table.tableStyle) {
                    case 'table1':
                        if (row === 0) {
                            return 'rgba(0,0,255,0.5)'
                        } else {
                            return undefined
                        }
                    case 'table2':
                        if (col === 0) {
                            return 'rgba(0,0,255,0.5)'
                        } else {
                            return undefined
                        }
                    case 'table3':
                        if (row === 0 || col === 0) {
                            return 'rgba(0,0,255,0.5)'
                        } else {
                            return undefined
                        }
                }
            }

            const color = () => {
                switch (table.tableStyle) {
                    case 'table1':
                        if (row === 0) {
                            return 'white'
                        } else {
                            return undefined
                        }
                    case 'table2':
                        if (col === 0) {
                            return 'white'
                        } else {
                            return undefined
                        }
                    case 'table3':
                        if (row === 0 || col === 0) {
                            return 'white'
                        } else {
                            return undefined
                        }
                }
            }

            return (
                <Box component="td"
                    sx={{
                        position: "relative",
                        height: '30px',
                        width: '300px',
                        backgroundColor: backgroundColor(),
                        color: color(),
                        whiteSpace: "pre-wrap",
                    }}
                >
                    {colValue}
                </Box>
            )
        }
        return (
            <>
                <Typography variant="h6" sx={{ fontSize: "1.2em", color: "rgba(0,0,0,0.5)" }}>表</Typography>
                <Box>
                    <pre>{htmlParse(table.headerText)}</pre>
                </Box>
                <Box component="table" sx={{ border: "solid 1px rgba(0,0,0,0.8)" }}>
                    <tbody>
                        {table.rows.map((row, i) => (
                            <tr key={`row${i}`}>
                                {row.map((col, j) => (
                                    renderCol(i, j, col)
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </Box>
                <Box>
                    <pre>{htmlParse(table.footerText)}</pre>
                </Box>
            </>
        )
    }

    const renderTable = (elem: TemplateElementItem) => {
        const spec = elem.tableSpec!
        const table = page.contents[elem.name].table!

        if (editingState != null && editingState.elementName === elem.name) {
            return (
                <Box component="section" sx={{ padding: 1 }}>
                    <div>
                        <h4>{elem.title}</h4>
                        <Typography variant="h4">{elem.title}</Typography>
                    </div>
                    {renderEditTable(TableForm)}
                </Box>
            )
        }

        return (
            <Box component="section" sx={{ padding: 1 }}>
                <div>
                    <Typography variant="h4">{elem.title}</Typography>
                    <Button type="button" variant="outlined" disabled={editingState != null} onClick={() => onClickEdit({ elementName: elem.name, contentType: 'table', contentId: table.id })}>編集</Button>
                </div>
                {renderTableContent(table, spec)}
            </Box>
        )
    }

    const renderList = (elem: TemplateElementItem) => {
        const list = page.contents[elem.name].list!
        const listSpec = elem.listSpec!
        const getString = (k: 'block' | 'table') => {
            if (listSpec!.strings == null) {
                switch (k) {
                    case 'block':
                        return '大見出し'
                    case 'table':
                        return '表'
                }
            }
            return listSpec.strings[k]
        }
        const hasAddBlockButton = () => {
            if (listSpec.elementTypes == null || listSpec.elementTypes.length === 0) {
                return true
            }
            if (listSpec.elementTypes.includes('block')) {
                return true
            }
            return false
        }
        const hasAddTableButton = () => {
            if (listSpec.elementTypes == null || listSpec.elementTypes.length === 0) {
                return true
            }
            if (listSpec.elementTypes.includes('table')) {
                return true
            }
            return false
        }
        return (
            <Box component="section" sx={{ padding: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', mb: 1 }}>
                    <Typography variant="h4">{elem.title}</Typography>
                </Box>
                {list.map((listItem, idx) => {
                    if (listItem.block != null) {
                        const block = listItem.block!
                        const spec = listSpec.blockSpec!
                        // 編集
                        if (editingState != null && editingState.elementName === elem.name && editingState.contentType === 'block' && editingState.contentId === block.id) {
                            return renderEditBlock(BlockForm)
                        }

                        const renderAppend = () => {
                            // ブロック追加
                            if (editingState != null && editingState.elementName === elem.name && editingState.contentType === 'block' && editingState.contentId === undefined && editingState.afterContentType === 'block' && editingState.afterContentId === block.id) {
                                return renderEditBlock(BlockForm)
                            }
                            // テーブル追加
                            if (editingState != null && editingState.elementName === elem.name && editingState.contentType === 'table' && editingState.contentId === undefined && editingState.afterContentType === 'block' && editingState.afterContentId === block.id) {
                                return renderEditTable(TableForm)
                            }
                            return <></>
                        }
                        return (
                            <Fragment key={`idx${idx}`}>
                                {renderAppend()}
                                <Box sx={{ mb: 2 }}>
                                    {renderBlockContent(block, spec)}
                                    <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'block', contentId: block.id, })}>編集</Button>
                                    <Button variant="text" onClick={() => onClickRemoveItemFromList(listItem, elem.name)}>削除</Button>
                                    {hasAddBlockButton() && (
                                        <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'block', contentId: undefined, afterContentType: 'block', afterContentId: block.id })}>上に{getString('block')}を追加 </Button>
                                    )}
                                    {hasAddTableButton() && (
                                        <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'table', contentId: undefined, afterContentType: 'block', afterContentId: block.id })}>上に{getString('table')}を追加 </Button>
                                    )}
                                    <Button variant="text" onClick={() => onClickListContentMoveUp(listItem, elem.name)} disabled={!isShowListContentMoveUp(listItem, elem.name)}>上に移動</Button>
                                    <Button variant="text" onClick={() => onClickListContentMoveDown(listItem, elem.name)} disabled={!isShowListContentMoveDown(listItem, elem.name)}>下に移動</Button>
                                    <hr />
                                </Box>
                            </Fragment>
                        )
                    } else if (listItem.table != null) {
                        const table = listItem.table!
                        const spec = elem.listSpec!.tableSpec!
                        // 編集
                        if (editingState != null && editingState.elementName === elem.name && editingState.contentType === 'table' && editingState.contentId === table.id) {
                            return renderEditTable(TableForm)
                        }
                        const renderAdd = () => {
                            // ブロック追加
                            if (editingState != null && editingState.elementName === elem.name && editingState.contentType === 'block' && editingState.contentId === undefined && editingState.afterContentType === 'table' && editingState.afterContentId === table.id) {
                                return renderEditBlock(BlockForm)
                            }
                            // テーブル追加
                            if (editingState != null && editingState.elementName === elem.name && editingState.contentType === 'table' && editingState.contentId === undefined && editingState.afterContentType === 'table' && editingState.afterContentId === table.id) {
                                return renderEditTable(TableForm)
                            }
                            return <></>
                        }
                        return (
                            <>
                                {renderAdd()}
                                <Box sx={{ mt: 3, mb: 2 }}>
                                    {renderTableContent(table, spec)}
                                    <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'table', contentId: table.id, })}>編集</Button>
                                    <Button variant="text" onClick={() => onClickRemoveItemFromList(listItem, elem.name)}>削除</Button>
                                    {hasAddBlockButton() && (
                                        <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'block', contentId: undefined, afterContentType: 'table', afterContentId: table.id })}>上に{getString('block')}を追加 </Button>
                                    )}
                                    {hasAddTableButton() && (
                                        <Button variant="text" onClick={() => onClickEdit({ elementName: elem.name, contentType: 'table', contentId: undefined, afterContentType: 'table', afterContentId: table.id })}>上に{getString('table')}を追加 </Button>
                                    )}
                                    <Button variant="text" onClick={() => onClickListContentMoveUp(listItem, elem.name)} disabled={!isShowListContentMoveUp(listItem, elem.name)}>上に移動</Button>
                                    <Button variant="text" onClick={() => onClickListContentMoveDown(listItem, elem.name)} disabled={!isShowListContentMoveDown(listItem, elem.name)}>下に移動</Button>
                                    <hr />
                                </Box>
                            </>
                        )
                    } else {
                        return <></>
                    }
                })}
                {editingState != null && editingState.elementName === elem.name && editingState.contentType === 'block' && editingState.contentId == null && editingState.afterContentType == null && editingState.afterContentId == null && (
                    renderEditBlock(BlockForm)
                )}
                {editingState != null && editingState.elementName === elem.name && editingState.contentType === 'table' && editingState.contentId == null && editingState.afterContentType == null && editingState.afterContentId == null && (
                    renderEditTable(TableForm)
                )}
                {hasAddBlockButton() && (
                    <Button
                        variant="text"
                        onClick={() => onClickEdit({ elementName: elem.name, contentType: 'block', contentId: undefined, afterContentId: undefined })}
                    >{getString('block')}を追加</Button>
                )}
                {hasAddTableButton() && (
                    <Button
                        variant="text"
                        onClick={() => onClickEdit({ elementName: elem.name, contentType: 'table', contentId: undefined, afterContentId: undefined })}
                    >{getString('table')}を追加</Button>
                )}
            </Box >
        )
    }

    const renderElement = (elem: TemplateElementItem): ReactElement => {
        switch (elem.type) {
            case 'block':
                return renderBlock(elem)
            case 'table':
                return renderTable(elem)
            case 'blockList':
                return renderBlockList(elem)
            case 'list':
                return renderList(elem)
        }
    }

    return (
        <Box sx={{ padding: 2, mb: 2 }}>
            <Box sx={{ mb: 1 }}>
                <Button type="button" variant="contained" color="primary" onClick={() => onClickPreview()}>プレビュー</Button>
                <Button type="button" variant="contained" color="primary" onClick={() => onClickDeploy()}>反映</Button>
            </Box>
            {page.template.elements.map((elem => (
                <Box key={elem.name} component={Paper} sx={{ padding: 2, mb: 2 }}>
                    {renderElement(elem)}
                </Box>
            )))}
        </Box>
    )
}

export default PageContentsView

type TemplateElementItem = Page['template']['elements'][number]

import { ComponentType, Dispatch, SetStateAction, useCallback, useEffect, useState } from "react"
import { ContactFormSettings } from "sgcms-models"
import { useServer } from "./providers/server"

const ContactFormSettingsEdit: ComponentType<Props> = ({ view: View }) => {
    const server = useServer()

    const [loading, setLoading] = useState(true)
    const [settings, setSettings] = useState<ContactFormSettings>({
        notificationEmailAddrs: [],
        items: [],
    })
    const getAsync = useCallback(async () => {
        setSettings(await server.getContactFormSettings())
    }, [server])

    useEffect(() => {
        getAsync().then(() => setLoading(false))
    }, [getAsync])

    const handleSave = () => {
    }

    if (loading) {
        return <></>
    }

    return (
        <View value={settings} setValue={setSettings} onSave={handleSave} />
    )
}

interface Props {
    view: ContactFormSettingsViewType
}

export default ContactFormSettingsEdit

export type ContactFormSettingsViewType = ComponentType<{
    value: ContactFormSettings
    setValue: Dispatch<SetStateAction<ContactFormSettings>>
    onSave: () => any
}>

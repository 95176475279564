import { Box, Button, TextField } from "@mui/material"
import { ChangeEvent, ComponentType, FormEvent, PropsWithChildren } from "react"
import { LoginViewType } from "../app/Login"

const LoginView:LoginViewType = ({values, setValue, onSubmit}) => {
    const handleSubmit = (evt:FormEvent) => {
        evt.preventDefault()
        onSubmit()
    }
    const handleChange = (key:string) => (evt:ChangeEvent<HTMLInputElement>) => {
        setValue(key, evt.target.value)
    }
    return (
        <Box>
            <Box sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100vw',
                height: '100vh',
            }}>
                <Box
                component='form'
                onSubmit={handleSubmit}
                sx={{
                    width: 500,
                    pl: 5,
                    pr: 5,
                    pt: 10,
                    pb: 5,
                    display: 'flex',
                    flexDirection: 'column',
                    border: 'solid 1px rgba(0,0,0,0.2)',
                    borderRadius: '5px',
                }}>
                    <Row>
                        <TextField variant="outlined"  value={values.email} onChange={handleChange('email')} label="ログインID" fullWidth />
                    </Row>
                    <Row>
                        <TextField variant="outlined" type="password" value={values.password} onChange={handleChange('password')} label="パスワード" fullWidth />
                    </Row>
                    <Box sx={{mb: 3, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <Button variant="contained" type="submit" color="primary" size="large" >ログイン</Button>
                    </Box>
                    <Box sx={{mb: 3, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <a href="/reminder">パスワードを忘れた方はこちら</a>
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}
export default LoginView

const Row:ComponentType<PropsWithChildren<{}>> = ({children}) => {
    return (
        <Box sx={{mb: 3}}>
            {children}
        </Box>
    )
}
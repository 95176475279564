import { ComponentType, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { NewsItem, SearchResult, SearchNewsOptions } from "sgcms-models"
import { useServer } from "./providers/server"

const News: ComponentType<Props> = ({ View }) => {
    const navigate = useNavigate()
    const server = useServer()
    const [opts, setOpts] = useState<SearchNewsOptions>({ page: 1, perPage: 100 })
    const [searchResult, setSearchResult] = useState<SearchResult<NewsItem>>({ total: 0, list: [] })
    const fetchAsync = useCallback(async () => {
        setSearchResult(await server.searchNewsItems(opts))
    }, [opts, server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    return (
        <View
            searchResult={searchResult}
            searchOptions={opts}
            onClickAdd={() => {
                navigate(`/news/new`)
            }}
            onClickModify={item => {
                navigate(`/news/${item.id}`)
            }}
            onClickDelete={async item => {
                await server.deleteNewsItem(item.id!)
                await fetchAsync()
            }}
            onClickPreview={item => {
                // navigate(`/preview/news/${item.id}`)
                window.open(`/preview/news/${item.id}`, 'preview')
            }}
            onClickPreviewList={() => {
                // navigate(`/preview/news`)
                window.open(`/preview/news`, 'preview')
            }}
        />
    )
}

interface Props {
    View: NewsViewType
}

export default News

export type NewsViewType = ComponentType<{
    searchResult: SearchResult<NewsItem>
    searchOptions: SearchNewsOptions
    onClickAdd: () => any
    onClickModify: (item: NewsItem) => any
    onClickDelete: (item: NewsItem) => any
    onClickPreview: (item:NewsItem) => any
    onClickPreviewList: () => any
}>

import { Box } from "@mui/material"
import { ComponentType, Dispatch, PropsWithChildren, SetStateAction } from "react"
import { ContactFormItem, ContactFormItemType, ContactFormSettings } from "sgcms-models"
import Form from "./Form"
import FormRow from "./FormRow"
import SaveButton from "./SaveButton"
import Select from "./Select"
import TextInput from "./TextInput"

export const ContactFormSettingsForm: ComponentType<Props> = ({ value, setValue, onSave }) => {
    const handleChangeNotificationEmails = (v: string) => {
        setValue(value => ({ ...value, notificationEmailAddrs: v === '' ? [] : v.split(',') }))
    }

    const changeItem = (i: number, f: (item: ContactFormItem) => ContactFormItem) => {
        setValue(value => ({
            ...value,
            items: value.items.map((item, j) => {
                if (i !== j) {
                    return item
                }
                return f(item)
            }),
        }))
    }

    const handleChangeItemName = (i: number) => (v: string) => {
        changeItem(i, item => ({ ...item, name: v }))
    }

    const handleChangeItemType = (i: number) => (v: string) => {
        changeItem(i, item => ({ ...item, itemType: v as ContactFormItemType }))
    }

    const renderTextItem = (i: number, item: ContactFormItem) => {
        return (
            <>
                <ItemRow>
                </ItemRow>
            </>
        )
    }

    const renderListItem = (i: number, item: ContactFormItem) => {
        return (
            <>
                <ItemRow></ItemRow>
            </>
        )
    }

    const renderItem = (i: number, item: ContactFormItem) => {
        switch (item.itemType) {
            case 'text':
                return renderTextItem(i, item)
            case 'list':
                return renderListItem(i, item)
            default:
                return <></>
        }
    }

    return (
        <Form onSubmit={onSave}>
            <FormRow>
                <TextInput label="送信先メールアドレス（カンマ区切りで複数可）" value={value.notificationEmailAddrs.join(',')} onChange={handleChangeNotificationEmails} />
            </FormRow>
            {value.items.map((item, i) => (
                <FormRow key={`${i}`}>
                    <ItemRow>
                        <TextInput label="項目名" value={item.name} onChange={handleChangeItemName(i)} />
                    </ItemRow>
                    <ItemRow>
                        <Select
                            label="項目タイプ"
                            value={item.itemType}
                            items={[
                                { value: "text", label: 'テキスト入力' },
                                { value: "list", label: '選択式' },
                            ]}
                            onChange={handleChangeItemType(i)}
                        />
                    </ItemRow>
                    {renderItem(i, item)}
                </FormRow>
            ))}
            <FormRow>
                <SaveButton>保存</SaveButton>
            </FormRow>
        </Form>
    )
}

export default ContactFormSettingsForm

interface Props {
    value: ContactFormSettings
    setValue: Dispatch<SetStateAction<ContactFormSettings>>
    onSave: () => any
}

const ItemRow: ComponentType<PropsWithChildren<{}>> = ({ children }) => <Box sx={{ mb: 1 }}>{children}</Box>
import { NewsCreateViewType } from "../app/NewsCreate";
import NewsFormView from "./NewsFormView";

const NewsCreateView:NewsCreateViewType = ({formProps}) => {
    return (
        <NewsFormView
            {...formProps}
        />
    )
}

export default NewsCreateView
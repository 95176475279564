import { Box } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { PageKind } from "sgcms-models"
import Preview from "./Preview"
import { useSite } from "./providers/site"

const PreviewPage = () => {
    const { kind } = useParams()
    const site = useSite()
    const [source, setSource] = useState<string | undefined>(undefined)
    const fetchAsync = useCallback(async () => {
        const html = await site.getPagePreview(kind! as PageKind)
        return html
        // (wnd as any).document.write(html)
    }, [kind, site])
    useEffect(() => {
        setSource(undefined)
        fetchAsync().then(source => {
            setSource(source)
        })
    }, [fetchAsync])
    return (
        <Box sx={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', zIndex: 9999, background: 'inherit' }}>
            {source != null && (
                <Preview
                    source={source}
                />
            )}
        </Box>
    )
}

export default PreviewPage

const source = `
<html>
<head>
</head>
<body>
<h1>TEST</h1>
</body>
</html>
`
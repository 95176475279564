import { ComponentType, useCallback, useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { NewsItem } from "sgcms-models"
import { NewsFormViewProps } from "./NewsForm"
import { useServer } from "./providers/server"

const NewsModify: ComponentType<Props> = ({ View }) => {
    const server = useServer()
    const params = useParams()
    const id = params.newsItemId!
    const [value, setValue] = useState<NewsItem | undefined>(undefined)
    const fetchAsync = useCallback(async () => {
        setValue(await server.getNewsItem(id))
    }, [id, server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    if (value == null) {
        return <></>
    }
    const handleSave = async () => {
        // server.createNewsItem(value!)
        await server.updateNewsItem(value)
        await fetchAsync()
        alert('保存しました')
    }
    const handlePublish = async () => {
        await server.updateNewsItem(value)
        await server.publishNewsItem(id)
        await fetchAsync()
        alert('変更しました')
    }

    return (
        <View
            formProps={{
                value: value,
                setValue: value => setValue(value),
                onSave: handleSave,
                isPublic: value.isPublic,
                onPublish: handlePublish,
            }}
        />
    )
}

interface Props {
    View: NewsModifyViewType
}

export default NewsModify

export type NewsModifyViewType = ComponentType<{
    formProps: NewsFormViewProps
}>
import { createContext, FC, useContext } from "react";
import { Server } from "sgcms-models"

export const ServerProvider:FC<ProviderProps> = ({server, children}) => {
    const value:ContextValue = {
        server,
    }

    return (
        <context.Provider value={value}>{children}</context.Provider>
    )
}

export const useServer = ():Server => useContext(context)!.server

const context = createContext<ContextValue|undefined>(undefined)

interface ContextValue {
    server:Server
}

interface ProviderProps {
    server:Server
}


import { Box, Drawer, List, ListItem, ListItemText, Divider } from "@mui/material"
import { FC } from "react"
import { useNavigate } from "react-router-dom"
import { useServer } from "./providers/server"
import { useSession } from "./providers/session"

const drawerWidth = 240

const naviItems = ['home', 'company', 'pages', 'themes', 'news', 'contactForm', 'contact', 'logout'] as const

type NaviItem = typeof naviItems[number]

const NaviContainer: FC<Props> = ({ children }) => {
    const server = useServer()
    const { fetchCurrent: fetchCurrentSession } = useSession()
    const navigate = useNavigate()
    const handleSelectLogout = async () => {
        await server.logout()
        await fetchCurrentSession()
    }
    const handleClickItem = (item: NaviItem) => () => {
        switch (item) {
            case 'home':
                navigate('/')
                return
            case 'company':
                navigate('/company')
                return
            case 'pages':
                navigate('/pages')
                return
            case 'themes':
                navigate('themes')
                return
            case 'contactForm':
                navigate('contact/settings')
                return
            case 'contact':
                navigate('contact/messages')
                return
            case 'news':
                navigate('news')
                return
            case 'logout':
                handleSelectLogout()
        }
    }
    return (
        <Box>
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
                variant="permanent"
                anchor="left"
            >
                <List>
                    <ListItem button onClick={handleClickItem('home')}>
                        <ListItemText primary="ホーム" />
                    </ListItem>
                    <ListItem button onClick={handleClickItem('company')}>
                        <ListItemText primary="会社情報・設定" />
                    </ListItem>
                    <ListItem button onClick={handleClickItem('news')}>
                        <ListItemText primary="ニュース・お知らせ" />
                    </ListItem>
                    <ListItem button onClick={handleClickItem('contact')}>
                        <ListItemText primary="問い合わせ" />
                    </ListItem>
                    {/*
                    <ListItem button onClick={handleClickItem('contactForm')}>
                        <ListItemText primary="問い合わせフォーム" />
                    </ListItem>
                    */}
                    <ListItem button onClick={handleClickItem('themes')} >
                        <ListItemText primary="外観・テーマ" />
                    </ListItem>
                </List>
                <Divider />
                <List>
                    <ListItem button onClick={handleClickItem('logout')} >
                        <ListItemText primary="ログアウト" />
                    </ListItem>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3, paddingLeft: '240px' }}
            >
                {children}
            </Box>
        </Box>
    )
}

export default NaviContainer

interface Props { }
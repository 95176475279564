import { Button } from "@mui/material"
import { ComponentType, PropsWithChildren } from "react"

const SaveButton:ComponentType<Props> = ({children}) => {
    return (
        <Button color="secondary" variant="contained">{children}</Button>
    )
}

export default SaveButton

type Props = PropsWithChildren<{}>
import { Box, Button } from "@mui/material"
import { ChangeEvent, FC } from "react"
import { Image } from "sgcms-models"
import { useSite } from "./providers/site"

const ImagePicker: FC<PickerProps> = ({ image, onUpload, onDelete }) => {
    if (image != null) {
        return (
            <Box sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <img src={image.url} alt="" style={{width: 200}} />
                <Button onClick={() => onDelete()}>削除</Button>
            </Box>
        )
    }
    return (
        <UploaderInput onUpload={onUpload} />
    )
}

export default ImagePicker

interface PickerProps {
    image: Image | undefined
    onDelete: () => any,
    onUpload: (image: Image) => any,
}

const UploaderInput: FC<UploaderInputProps> = ({ onUpload }) => {
    const site = useSite()
    const handleChange = async (evt: ChangeEvent<HTMLInputElement>) => {
        const files = evt.target.files
        if (files == null || files.length === 0) {
            return
        }
        const image = await site.addImage(files[0])
        onUpload(image)
    }
    return (
        <input type="file" onChange={handleChange} />
    )
}

interface UploaderInputProps {
    onUpload: (image: Image) => any
}

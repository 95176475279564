import { ComponentType, PropsWithChildren, ReactElement, useEffect } from "react"
import { useSession } from "./providers/session"

export const SessionLoader: ComponentType<PropsWithChildren<Props>> = ({loginElement, children}) => {
    const { isFetched, fetchCurrent, session } = useSession()

    useEffect(() => {
        fetchCurrent()
    }, [fetchCurrent])

    if (!isFetched) {
        return <></>
    }
    if (session == null) {
        return loginElement
    }
    return (
        <>{children}</>
    )
}

interface Props {
    loginElement: ReactElement
}
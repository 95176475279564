import { ComponentType, Dispatch, SetStateAction, useState } from "react"
import { setConstantValue } from "typescript"
import { useServer } from "./providers/server"
import { useSession } from "./providers/session"

const Login:ComponentType<Props> = ({View}) => {
    const server = useServer()
    const {session, isFetched, fetchCurrent} = useSession()
    const [values , setValues] = useState({
        email: "",
        password: "",
    })
    const setValue = (key:string, value: string) => {
        setValues(values => ({
            ...values,
            [key]: value,
        }))
    }
    const handleSubmit = async () => {
        await server.login(values.email, values.password)
        await fetchCurrent()
    }
    return (
        <View
            onSubmit={handleSubmit}
            values={values}
            setValue={setValue}
        />

    )
}

export default Login

export type LoginViewType = ComponentType<{
    onSubmit: () => any
    values: {
        email: string
        password: string
    }
    setValue: (key:string, value: string) => any
}>

interface Props {
    View: LoginViewType
}

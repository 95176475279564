import { Box } from "@mui/material"
import { ComponentType, useCallback, useEffect, useLayoutEffect, useRef } from "react"
import { useSite } from "./providers/site"

const Preview: ComponentType<Props> = ({ source }) => {

    const ref = useRef<HTMLIFrameElement | null>(null)
    useLayoutEffect(() => {
        ref.current!.srcdoc = source
    }, [source])
    return (
        <Box
            component="iframe"
            title="preview"
            ref={ref}
            sx={{
                width: '100%',
                height: '100%',
                zIndex: 9999,
            }}
        />
    )
}

export default Preview

interface Props {
    source: string
}
import { Box } from "@mui/material"
import { ComponentType, PropsWithChildren } from "react"

const Main:ComponentType<Props> = ({children}) => {
    return (
        <Box sx={{padding: 1}}>{children}</Box>
    )
}

export default Main

type Props = PropsWithChildren<{}>
import { ComponentType, useCallback, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { ContactMessage, SearchContactMessagesOptions, SearchResult } from "sgcms-models"
import { useServer } from "./providers/server"

const Contacts: ComponentType<Props> = ({ View }) => {
    const navigate = useNavigate()
    const server = useServer()
    const [opts, setOpts] = useState<SearchContactMessagesOptions>({ page: 1, perPage: 100 })
    const [searchResult, setSearchResult] = useState<SearchResult<ContactMessage>>({ total: 0, list: [] })
    const fetchAsync = useCallback(async () => {
        setSearchResult(await server.searchContactMessages(opts))
    }, [opts, server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    return (
        <View
            result={searchResult}
            onClickPreview={() => {
                // navigate(`/preview/contact`)
                window.open(`/preview/contact`, 'preview')
            }}
            onClickDeploy={async () => {
                await server.deployContactPage()
                alert('反映しました')
            }}
            onClickDetail={item => {
                navigate(`/contact/messages/${item.id}`)
            }}
        />
    )
}

interface Props {
    View: ContactsViewType
}

export default Contacts

export type ContactsViewType = ComponentType<{
    onClickPreview: () => any,
    onClickDeploy: () => any,
    onClickDetail: (item:ContactMessage) => any,
    result: SearchResult<ContactMessage>,
}>

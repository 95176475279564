import { Box, Button, TextField, Typography } from "@mui/material"
import { ChangeEvent, ComponentType, useState } from "react"
import { FormEvent } from "react"
import { Company } from "sgcms-models"
import { useServer } from "./providers/server"

const CompanyForm: ComponentType<Props> = ({ value, onChange, onSubmit }) => {
    const server = useServer()
    const handleSubmit = (evt: FormEvent) => {
        try {
            evt.preventDefault()
            onSubmit()
        } finally {
            return false
        }
    }
    const handleChange = (key: keyof Company) => (evt: ChangeEvent<HTMLInputElement>) => {
        onChange({
            ...value,
            [key]: evt.target.value,
        })
    }

    const renderTextField = (name: keyof Company, label: string) => {
        return (
            <Box sx={{ padding: 1, mb: 1 }}>
                <TextField
                    name={name}
                    value={value[name]}
                    onChange={handleChange(name)}
                    multiline
                    rows={3}
                    maxRows={3}
                    fullWidth
                    label={label}
                />
            </Box>
        )
    }

    const handleChangeLogoImage = async (evt: ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const id = await server.uploadImage(evt.target.files![0])
        const image = await server.getImage(id)
        onChange({
            ...value,
            logoUrl: image.url,
        })
    }

    const handleChangeFaviconImage = async (evt: ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault()
        const id = await server.uploadImage(evt.target.files![0])
        const image = await server.getImage(id)
        onChange({
            ...value,
            faviconImageUrl: image.url,
        })
    }

    const handleChangeLegalFile = (filetype: string) => async (evt: ChangeEvent<HTMLInputElement>) => {
        evt.preventDefault()
        let url
        switch (filetype) {
            case "privacy":
                url = await server.uploadPrivacyFile(evt.target.files![0])
                onChange({
                    ...value,
                    privacyFileUrl: url,
                })
                break
            case "terms":
                url = await server.uploadTermsFile(evt.target.files![0])
                onChange({
                    ...value,
                    termsFileUrl: url,
                })
                break
        }
        evt.target.value = ""
    }

    const handleChangeEmblemImage = async (evt: ChangeEvent<HTMLInputElement>) => {
        try {
            evt.preventDefault()
            const imageId = await server.uploadImage(evt.target.files![0])
            const url = (await server.getImage(imageId)).url
            onChange({
                ...value,
                emblemImageURLs: [...value.emblemImageURLs, url]
            })
        } finally {
            evt.target.value = ""
        }
    }

    const handleDeleteEmblemImage = (idx: number) => () => {
        onChange({
            ...value,
            emblemImageURLs: value.emblemImageURLs.filter((v, i) => i !== idx),
        })
    }

    return (
        <form onSubmit={handleSubmit}>
            {renderTextField('name', '事務所名')}
            {renderTextField('address', '所在地')}
            {renderTextField('access', 'アクセス')}
            {renderTextField('tel', 'TEL')}
            {renderTextField('fax', 'FAX')}
            {renderTextField('email', 'メールアドレス')}
            {renderTextField('daihyo', '代表')}
            {renderTextField('kaigyo', '事務所開業')}
            {renderTextField('employees', '事務所体制')}
            {renderTextField('jikan', '受付時間')}
            {renderTextField('others', 'その他')}
            {renderTextField('googleMap', 'Google Map')}
            {renderTextField('copy1', 'ヘッダーキャッチコピー')}
            {renderTextField('contactNotice', 'お問い合わせリンクの注意書き1')}
            {renderTextField('contactNotice2', 'お問い合わせリンクの追加のテキスト')}
            {/* renderTextField('trackingCodeBody', 'トラッキングコード(body部)') */}
            <Box sx={{ padding: 1, mb: 1 }}>
                <Typography>ロゴ画像</Typography>
                {value.logoUrl === "" && (
                    <input type="file" onChange={handleChangeLogoImage} />
                )}
                {value.logoUrl !== "" && (
                    <>
                        <img src={value.logoUrl} style={{ width: '200px' }} alt="" />
                        <Button onClick={() => {
                            onChange({
                                ...value,
                                logoUrl: "",
                            })
                        }}>削除</Button>
                    </>
                )}
            </Box>
            <Box sx={{ padding: 1, mb: 1 }}>
                <Typography>ホームページアイコン (推奨サイズ: 縦横それぞれ512ピクセル以上)</Typography>
                {value.faviconImageUrl === "" && (
                    <input type="file" onChange={handleChangeFaviconImage} />
                )}
                {value.faviconImageUrl !== "" && (
                    <>
                        <img src={value.faviconImageUrl} style={{ width: '200px' }} alt="" />
                        <Button onClick={() => {
                            onChange({
                                ...value,
                                faviconImageUrl: "",
                            })
                        }}>削除</Button>
                    </>
                )}
            </Box>
            <Box sx={{ padding: 1, mb: 1 }}>
                <Typography>個人情報方針</Typography>
                {value.privacyFileUrl === "" && (
                    <input type="file" onChange={handleChangeLegalFile("privacy")} />
                )}
                {value.privacyFileUrl !== "" && (
                    <>
                        <Typography color="blue">アップロード済み</Typography>
                        <Button onClick={() => {
                            onChange({
                                ...value,
                                privacyFileUrl: "",
                            })
                        }}>削除</Button>
                    </>
                )}
            </Box>
            <Box sx={{ padding: 1, mb: 1 }}>
                <Typography>利用規約</Typography>
                {value.termsFileUrl === "" && (
                    <input type="file" onChange={handleChangeLegalFile("terms")} />
                )}
                {value.termsFileUrl !== "" && (
                    <>
                        <Typography color="blue">アップロード済み</Typography>
                        <Button onClick={() => {
                            onChange({
                                ...value,
                                termsFileUrl: "",
                            })
                        }}>削除</Button>
                    </>
                )}
            </Box>
            <Box sx={{ padding: 1, mb: 1 }}>
                <Typography>認定マーク</Typography>
                {value.emblemImageURLs.map((item, idx) => (
                    <Box sx={{ display: 'flex', flexDirection: 'row', padding: 1, mb: 1 }} key={`${idx}`}>
                        <Box component="img" sx={{ width: 100, maxHeight: 50 }} alt="" src={item} />
                        <Button onClick={handleDeleteEmblemImage(idx)}>削除</Button>
                    </Box>
                ))}
                <Box sx={{ padding: 1, mb: 1 }}>
                    <input type="file" onChange={handleChangeEmblemImage} />
                </Box>
            </Box>
            {renderTextField('siteTitle', 'HTML titleタグ')}
            {renderTextField('siteDescription', 'HTML descriptionタグ')}
            {renderTextField('trackingCodeHead', 'Googleアナリティクストラッキングコード')}
            <Box sx={{ padding: 1 }}>
                <Button type="submit" color="secondary" variant="contained" >反映</Button>
            </Box>
        </form >
    )
}

export default CompanyForm

interface Props {
    value: Company
    onChange: (value: Company) => any
    onSubmit: () => any
}


export const contactFormItemTypes = ['text', 'list'] as const

export type ContactFormItemType = typeof contactFormItemTypes[number]

export const contactFormItemListTypes = ['combo', 'checkbox', 'radio'] as const

export type ContactFormItemListType = typeof contactFormItemListTypes[number]

export interface ContactFormSettings {
    items: ContactFormItem[]
    notificationEmailAddrs: string[]
}

export interface ContactFormItem {
    name: string
    label: string
    itemType: ContactFormItemType
    options: ContactFormItemOptions,
}

export interface ContactFormItemOptions {
    required?: boolean
    multiline?: boolean
    listType?: ContactFormItemListType
}

export interface ContactFormSettingsServer {
    updateContactFormSettings(settings: ContactFormSettings): Promise<void>
    getContactFormSettings():Promise<ContactFormSettings>
}

import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material"
import { NewsViewType } from "../app/News"
import Main from "./Main"
import { format } from 'date-fns'

const NewsView: NewsViewType = ({ searchResult, onClickAdd, onClickModify, onClickDelete, onClickPreview, onClickPreviewList }) => {
    return (
        <Main>
            <Typography variant="h6">ニュース・お知らせ</Typography>
            <Box sx={{ pt: 1, pb: 1 }}>
                <Box component={Button}
                    variant="contained"
                    onClick={() => onClickAdd()}
                    color="primary"
                >新規作成</Box>
                <Box
                    component={Button}
                    sx={{ ml: 1 }}
                    variant="contained"
                    onClick={() => onClickPreviewList()}
                    color="primary"
                >プレビュー</Box>
            </Box>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>記事タイトル</TableCell>
                        <TableCell>日付</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {searchResult.list.map(item => (
                        <TableRow>
                            <TableCell>{item.title}</TableCell>
                            <TableCell>{format(item.date, "yyyy/MM/dd")}</TableCell>
                            <TableCell>
                                <Button onClick={() => onClickPreview(item)}>プレビュー</Button>
                                <Button onClick={() => onClickModify(item)}>修正</Button>
                                <Button onClick={() => onClickDelete(item)}>削除</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Main>
    )
}

export default NewsView

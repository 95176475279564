import { ComponentType, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ContactMessage } from "sgcms-models";
import { useServer } from "./providers/server";

const ContactDetail:ComponentType<Props> = ({View}) => {
    const params = useParams()
    const server = useServer()
    const messageId = params.messageId!
    const [message, setMessage] = useState<ContactMessage|undefined>(undefined)
    const fetchAsync = useCallback(async () => {
        setMessage(await server.getContactMessage(messageId))
    }, [messageId, server])
    useEffect(() => {
        fetchAsync()
    }, [fetchAsync])
    if (message == null) {
        return <></>
    }
    return (
        <View
            message={message}
        />
    )
}

interface Props {
    View: ContactDetailViewType,
}

export default ContactDetail

export type ContactDetailViewType = ComponentType<{
    message: ContactMessage,
}>

import { ContactFormSettingsViewType } from "../app/ContactFormSettingsEdit"
import ContactFormSettingsForm from "./ContactFormSettingsForm"
import Main from "./Main"

const ContactFormSettingsEditView: ContactFormSettingsViewType = ({ value, onSave, setValue }) => {
    return (
        <Main>
            <ContactFormSettingsForm value={value} onSave={onSave} setValue={setValue} />
        </Main>
    )
}

export default ContactFormSettingsEditView

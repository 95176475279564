import { Button, TextField, Typography } from "@mui/material";
import { FormEventHandler } from "react";
import { NewsFormViewType } from "../app/NewsForm";
import FormRow from "./FormRow";
import TextEditor from "./TextEditor";

const NewsFormView: NewsFormViewType = ({ onSave, setValue, value, isPublic, onPublish }) => {
    const handleSubmit: FormEventHandler = evt => {
        evt.preventDefault()
        onSave()
    }

    const renderSubmitLabel = () => {
        if (isPublic) {
            return "変更"
        } else {
            return "下書き保存"
        }
    }

    const renderSubmitButton = () => {
        if (isPublic) {
            return (
                <Button
                    type="submit"
                    color="secondary"
                    variant="contained"
                >
                    変更
                </Button>
            )
        } else {
            return (
                <Button
                    type="submit"
                    color="primary"
                    variant="outlined"
                >
                    下書き保存
                </Button>
            )
        }

    }

    return (
        <form onSubmit={handleSubmit}>
            <FormRow>
                <TextField
                    label="記事タイトル"
                    value={value.title}
                    onChange={evt => setValue({
                        ...value,
                        title: evt.target.value,
                    })}
                    sx={{ width: 400 }}
                />

            </FormRow>
            <FormRow>
                <Typography variant="h3">本文</Typography>
                <TextEditor
                    onChange={text => setValue({
                        ...value,
                        text,
                    })}
                    value={value.text}
                />
            </FormRow>
            <FormRow>
                {renderSubmitButton()}
                {!isPublic && (
                    <Button color="secondary" variant="contained" onClick={() => onPublish()}>投稿</Button>
                )}
            </FormRow>
        </form>
    )
}

export default NewsFormView

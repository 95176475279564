import { Box, Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { ContactsViewType } from "../app/Contact";
import Main from "./Main";
import { format } from 'date-fns'

const ContactsView: ContactsViewType = ({ onClickPreview, result, onClickDetail, onClickDeploy }) => {
    return (
        <Main>
            <Typography variant="h6">お問い合わせ</Typography>
            <Box sx={{ pt: 1, pb: 1 }}>
                <Button
                    variant="contained"
                    onClick={() => onClickPreview()}
                    color="primary"
                >プレビュー</Button>
                <Button
                    variant="contained"
                    onClick={() => onClickDeploy()}
                    color="primary"
                >反映</Button>
            </Box>

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>日付</TableCell>
                        <TableCell>お名前</TableCell>
                        <TableCell>会社名</TableCell>
                        <TableCell>メールアドレス</TableCell>
                        <TableCell>電話番号</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {result.list.map(item => (
                        <TableRow>
                            <TableCell>{format(item.createdAt, "yyyy/MM/dd HH:mm")}</TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.company}</TableCell>
                            <TableCell>{item.email}</TableCell>
                            <TableCell>{item.tel}</TableCell>
                            <TableCell>
                                <Button onClick={() => onClickDetail(item)}>詳細</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Main>
    )
}

export default ContactsView
import { ComponentType, FormEvent, PropsWithChildren } from "react"

const Form:ComponentType<Props> = ({onSubmit, children}) => {
    const handleSubmit = (evt:FormEvent) => {
        evt.preventDefault()
        onSubmit()
    }
    return (
        <form onSubmit={handleSubmit}>
            {children}
        </form>
    )
}

export default Form

type Props = PropsWithChildren<{
    onSubmit: () => any
}>

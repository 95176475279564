import { SiteProvider } from './providers/site';
import { DevelopmentServer } from '../server/development';
import Home from './Home';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import Pages from './Pages';
import PageContents from './PageContents';
import Themes from './Themes';
import NaviContainer from './NaviContainer';
import CompanyEdit from './CompanyEdit';
import PreviewPage from './PreviewPage';
import ContactFormSettings from './ContactFormSettingsEdit';
import ContactFormSettingsEditView from '../views/ContactFormSettingsEditView';
import { ServerProvider } from './providers/server';
import { ApiServer } from 'sgcms-api'
import { SessionProvider } from './providers/session';
import LoginView from '../views/LoginView';
import Login from './Login';
import { SessionLoader } from './SessionLoader';
import { CssBaseline } from '@mui/material';
import PageContentsView from '../views/PageContentView';
import News from './News';
import NewsView from '../views/NewsView';
import NewsCreate from './NewsCreate';
import NewsCreateView from '../views/NewsCreateView';
import NewsModify from './NewsModify';
import NewsModifyView from '../views/NewsModifyView';
import PreviewNewsItem from './PreviewNewsItem';
import PreviewContact from './PreviewContact';
import Contacts from './Contact';
import ContactsView from '../views/ContactView';
import ContactDetail from './ContactDetail';
import ContactDetailView from '../views/ContactDetailView';
import PreviewNews from './PreviewNews';
import PagesView from '../views/PagesView';

function App() {
  return (
    <>
      <CssBaseline />
      <BrowserRouter>
        <ServerProvider server={server}>
          <Routes>
            <Route path="/registration" element={<></>} />
            <Route path="/*" element={
              <SessionProvider server={server} loginElement={<Login View={LoginView} />}>
                <SessionLoader loginElement={<Login View={LoginView} />}>
                  <SiteProvider server={server}>
                    <NaviContainer>
                      <Outlet />
                    </NaviContainer>
                  </SiteProvider>
                </SessionLoader>
              </SessionProvider>

            } >
              <Route index element={<Pages View={PagesView} />} />
              <Route path='pages' element={<Pages View={PagesView} />} />
              <Route path='pages/:kind/contents' element={<PageContents View={PageContentsView} />} />
              <Route path="themes" element={<Themes />} />
              <Route path="company" element={<CompanyEdit />} />
              <Route path="contact/messages" element={<Contacts View={ContactsView} />} />
              <Route path="contact/messages/:messageId" element={<ContactDetail View={ContactDetailView} />} />
              <Route path="contact/settings" element={<ContactFormSettings view={ContactFormSettingsEditView} />} />
              <Route path="preview/news/:newsItemId" element={<PreviewNewsItem />} />
              <Route path="preview/news" element={<PreviewNews />} />
              <Route path="preview/contact" element={<PreviewContact />} />
              <Route path="preview/:kind" element={<PreviewPage />} />
              <Route path="news" element={<News View={NewsView} />} />
              <Route path="news/new" element={<NewsCreate View={NewsCreateView} />} />
              <Route path="news/:newsItemId" element={<NewsModify View={NewsModifyView} />} />
            </Route>
          </Routes>
        </ServerProvider>
      </BrowserRouter>
    </>
  );
}

export default App;

// const server = new DevelopmentServer()
const server = new ApiServer(window.fetch.bind(window), "")

